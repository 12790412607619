







































































































































































































































































import ActivityLog from '@/components/ActivityLog.vue'
import PopupEditUser from '@/components/Administration/Users/PopupEditUser.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { Ability } from '@/types/Ability'
import { CandidatJobDescriptionStatus, getCandidatJobDescriptionStatus } from '@/types/CandidatJobDescription'
import { getJobDescriptionStatus } from '@/types/JobDescription'
import { base64ToArrayBuffer, dateisSameOrBefore, formatDateVariante } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PopupAddCandidature from '@/components/BourseEmploi/PopupAddCandidature.vue'

@Component({
    methods: {
        getJobDescriptionStatus,
        formatDateVariante
    },
    components: {
        ExaGenericTable,
        PopupEditUser,
        'font-awesome-icon': FontAwesomeIcon,
        'activity-log': ActivityLog,
        VuePdfApp,
        ErrorDisplay,
        EditorTinyMCE,
        PopupEditCandidat,
        PopupAddCandidature
    },
    computed: {
        Ability() {
            return Ability
        },
        ...mapGetters('candidatJobDescription', ['candidatJobDescriptions', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error']),
        ...mapGetters('auth', ['authUser', 'can'])
    }
})

export default class ListeCandidatures extends Vue {
    filtres: any = []
    filtreJustInitiated = false
    dataForTab: Array<any> = []
    genericfields: Array<any> = []
    showCandidatModal = false
    showAddCandidature = false
    showJobModal = false
    showEditStatus = false
    showConfirmEditStatus = false
    showJobDocument = false
    jobToShow: any = null
    candidatureLoading = false
    statusToEdit: any = null
    candidatureStatus: any = null
    currentStatus: any = null
    showModalParametrages = false
    search = ''

    jobVisibility: any = {
        start_at: null,
        end_at: null
    }

    jobCandidature: any = {
        start_at: null,
        end_at: null
    }

    documentToShow: any = {
        name: null,
        content: null
    }

    config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    // ------historique
    formatDateVariante = formatDateVariante

    togglePopupHistoriqueJobDescription = false
    errorKeeper = null
    lastSave: any = null
    historiqueJobDescription: any = null

    /**
     * Toggle le popup d'historique des validations / invalidations des barres
     * @param {any} candidatJobDescription_id - ID de la candidature
     * @returns {void}
     */
    openPopupHistoriqueJobDescription(candidatJobDescription_id?: any): void {
        if (this.isInDetailEstablishment()) {
            return
        }

        this.errorKeeper = null
        this.historiqueJobDescription = null
        this.$store.dispatch('candidatJobDescription/getActivityLog', { candidatJobDescription_id: candidatJobDescription_id })
            .then((response) => {
                this.historiqueJobDescription = response.data.data
                this.togglePopupHistoriqueJobDescription = true
            })
    }

    closePopupHistoriqueJobDescription(): void {
        this.historiqueJobDescription = null
        this.togglePopupHistoriqueJobDescription = false
    }

    // -----------------

    /**
     * @description Vérifie si l'utilisateur peut éditer
     * @returns {boolean}
     */
    canEdit(): boolean {
        const can = this.$store.getters['auth/can']
        if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
            return true
        }
        if (can(Ability.INTERV_OWN_ESTABLISHMENT)) {
            const session = this.$store.getters['session/sessions']
                .find((session: any) => session.id === this.$store.getters['auth/user_session_id'])
            const startAt = session.job_descriptions_start_at
            const endAt = session.job_descriptions_end_at

            if (startAt && endAt) {
                const start = new Date(startAt)
                const end = new Date(endAt)
                const now = new Date()

                if (now >= start && now <= end) {
                    return true
                }
            }
        }
        return false
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    async loadDatas(): Promise<void> {
        const params: any = {
            page: 1,
            sort: 'name',
            direction: 'asc'
        }

        if (this.isInDetailEstablishment()) {
            params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        localStorage.setItem('candidatureParams', JSON.stringify(params))

        await this.$store.dispatch('candidatJobDescription/getCandidatJobDescriptions', params)
    }

    /**
     * @description Mise à jour du tableau des fiches de poste
     * @returns {void}
     */
    @Watch('candidatJobDescriptions')
    onCandidatJobDescriptionsChange(): void {
        this.setDataForGenericTab(this.$store.getters['candidatJobDescription/candidatJobDescriptions'])
    }

    /**
     * @description Ouverture de la modale d'ajout de candidature
     * @returns {Promise<void>}
     */
    async openAddCandidature(): Promise<void> {
        this.showAddCandidature = true
    }

    /**
     * @description Fermeture de la modale d'ajout de candidature
     * @returns {void}
     */
    closeAddCandidature(): void {
        this.showAddCandidature = false
    }

    /**
     * @description Ouvre la modale de consultation des informations du candidat
     * @param {any} candidat - Candidat à consulter
     * @returns {void}
     */
    openCandidatModal(candidat: any): void {
        this.$store.commit('candidatJobDescription/SET_LOADING', true)
        this.$store.dispatch('candidat/getCandidat', { id: candidat.id })
            .then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                this.showCandidatModal = true
            })
            .finally(() => {
                this.$store.commit('candidatJobDescription/SET_LOADING', false)
            })
    }

    /**
     * @description Fermeture de la modale de consultation des informations du candidat
     * @returns {void}
     */
    closeCandidatModal() {
        this.showCandidatModal = false
    }

    /**
     * @description Ouverture de la modale de consultation de fiche de poste
     * @param {any} job - Fiche de poste à consulter
     * @returns {void}
     */
    openJobModal(job: any): void {
        this.$store.commit('candidatJobDescription/SET_ERROR', null)
        this.jobToShow = JSON.parse(JSON.stringify(job))
        this.showJobModal = true
    }

    /**
     * @description Fermeture de la modale d'ajout de fiche de poste
     * @returns {void}
     */
    closeJobModal(): void {
        this.showJobModal = false
        this.jobToShow = null
        this.$store.commit('candidatJobDescription/SET_ERROR', null)
    }

    /**
     * @description Ouverture de la modale de consultation d'un document de la fiche de poste
     * @param {any} job - Fiche de poste
     * @param {number} index - Index du document
     * @returns {Promise<void>}
     */
    async openJobDocument(job: any, index: number): Promise<void> {
        this.documentToShow.name = job.documents[index].name
        const response = await this.$store.dispatch('jobDescription/getJobDocument', {
            job_id: job.id, document_uuid: job.documents[index].uuid
        })
        this.documentToShow.content = base64ToArrayBuffer(response.data)
        this.showJobDocument = true
    }

    /**
     * @description Fermeture de la modale de consultation d'un document de la fiche de poste
     * @returns {void}
     */
    closeJobDocument(): void {
        this.showJobDocument = false
        this.documentToShow = {
            name: null,
            content: null
        }
    }

    /**
     * @description Ouverture de la modale d'édition du statut de la candidature
     * @param {any} candidature - Candidature à modifier
     * @returns {void}
     */
    openEditStatus(candidature: any): void {
        this.statusToEdit = candidature
        this.currentStatus = JSON.parse(JSON.stringify(candidature.status))
        this.showEditStatus = true
    }

    /**
     * @description Fermeture de la modale d'édition du statut de la candidature
     * @returns {void}
     */
    closeEditStatus(): void {
        this.showEditStatus = false
        this.statusToEdit = null
        this.currentStatus = null
    }

    /**
     * @description Ouverture de la confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    openConfirmEditStatus(): void {
        this.showConfirmEditStatus = true
    }

    /**
     * @description Confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    confirmEditStatus(): void {
        this.closeConfirmEditStatus()
        this.updateStatus(false)
    }

    /**
     * @description Fermeture de la confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    closeConfirmEditStatus(): void {
        this.showConfirmEditStatus = false
    }

    /**
     * @description Mise à jour du statut de la candidature
     * @returns {void}
     */
    updateStatus(checkCandidaciesValidated = true): void {
        if (this.candidatureLoading) {
            return
        }

        if (checkCandidaciesValidated && this.statusToEdit.status === CandidatJobDescriptionStatus.STATUS_ACCEPTED) {
            const otherCandidacies = this.statusToEdit.jobDescription.candidaciesValidated
                .filter((candidature: any) => candidature.id !== this.statusToEdit.id)

            if (otherCandidacies.length > 0) {
                this.openConfirmEditStatus()
                return
            }
        }

        this.candidatureLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('candidatJobDescription/putCandidatJobDescriptionStatus', {
            id: this.statusToEdit.id,
            status: this.statusToEdit.status
        })
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.setDataForGenericTab(this.$store.getters['candidatJobDescription/candidatJobDescriptions'])
            })
            .finally(() => {
                this.closeEditStatus()
                this.candidatureLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Tronquer une chaîne de caractères
     * @param {string} str - Chaîne de caractères à tronquer
     * @param {number} length - Longueur maximale de la chaîne
     * @returns {string} - Chaîne tronquée
     */
    sliceString(str: string, length = 100): string {
        if (str) {
            const temp = document.createElement('div')
            temp.innerHTML = str
            str = temp.textContent || str
            temp.remove()
            str = str.replace(/\s\s+/g, ' ')

            return str.length > length ? `${str.slice(0, length)}...` : str
        }
        return '-'
    }

    /**
     * @description Remplissage du tableau des fiches de poste
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const candidat: string = result.candidat ? `${result.candidat.name} ${result.candidat.first_name}` : '-'
                const establishment = `<button type="button" class="m-0 p-0 text-info ${this.$route.path === `/bourse_emploi/etablissements/${result.jobDescription.workingEstablishment.id}` ? 'pe-none' : ''}" onclick="handleTableEvent(['openEtablissement', ${result.jobDescription.workingEstablishment.id}])">${result.jobDescription.workingEstablishment.name}</button>`

                const status = getCandidatJobDescriptionStatus(result.status)
                const statusTitle = `${status.name} ${result.updated_at ? `le ${formatDateVariante(result.updated_at)}` : ''}`
                const puceStatus = [{ name: 'circle', class: `text-${status.color}`, title: statusTitle, result: result }]
                const link = [{ name:'arrow-circle-right', class: 'text-info', id: result.id, result: result.id }]

                if ((this.lastSave === null && result.updated_at) || (this.lastSave && result.updated_at && dateisSameOrBefore(this.lastSave, result.updated_at))) {
                    this.lastSave = result.updated_at
                }
                const updatedAt = (result.updated_at ? formatDateVariante(result.updated_at) : ' - ')
                const updatedAtIcon = [{
                    name: 'clock',
                    class: 'text-info text-small text-start',
                    title: updatedAt,
                    value_comp: updatedAt,
                    typeAction: 'popupHistorique',
                    item: result
                }]

                const datas: any = []
                datas.push(
                    { label: 'Cliquez pour consulter les informations du candidat', text: candidat,                          item: result.candidat,                                         type: 'actionText', typeAction: 'showCandidat',       class: 'text-info'                                        },
                    { label: 'Cliquez pour consulter la fiche de poste',            text: result.jobDescription.name || '-', item: result.jobDescription,                                   type: 'actionText', typeAction: 'showJobDescription', class: 'text-info'                                        },
                    { label: '',                                                                                             item: this.sliceString(result.jobDescription.description), type: 'html',       typeAction: null,                 class: 'one-line'                                         },
                    { label: '',                                                                                             item: establishment,                                           type: 'html',       typeAction: null,                 class: ''                                                 },
                    { label: '',                                                                                             item: puceStatus,                                              type: 'icons',      typeAction: 'editStatus',         class: `text-center ${(this.canEdit() ? '' : 'pe-none')}` },
                    { label: '',                                                                                             item: updatedAtIcon,                                           type: 'icons',      typeAction: 'popupHistorique',    class: 'text-center'                                      },
                    { label: 'Accéder à la candidature',                                                                     item: link,                                                    type: 'icons',      typeAction: 'showCandidature',    class: 'text-center edit'                                 }
                )

                if (this.isInDetailEstablishment()) {
                    datas.splice(3, 1)
                }

                this.dataForTab.push(datas)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        const middle = Math.floor(Object.keys(CandidatJobDescriptionStatus).length / 2)
        this.candidatureStatus = Object.keys(CandidatJobDescriptionStatus).slice(0, middle)
            .map(status => getCandidatJobDescriptionStatus(parseInt(status)))

        this.filtres = [
            { libelle: 'Candidat',      defautOptionlibelle: 'Rechercher un', model: 'candidat.name'             , value: '', index: 'candidat.name'             , datas: null          , loading: this.$store.getters['jobDescription/loading'], options: { type: 'form'     , fieldsKey: 'candidat.name'              } },
            { libelle: 'Intitulé',      defautOptionlibelle: 'Rechercher un', model: 'jobDescription.name'       , value: '', index: 'jobDescription.name'       , datas: null          , loading: this.$store.getters['jobDescription/loading'], options: { type: 'form'     , fieldsKey: 'jobDescription.name'        } },
            { libelle: 'Établissement', defautOptionlibelle: 'Rechercher un', model: 'working_establishment_name', value: '', index: 'working_establishment_name', datas: null          , loading: this.$store.getters['jobDescription/loading'], options: { type: 'form'     , fieldsKey: 'working_establishment_name' } },
            { libelle: 'Statut',        defautOptionlibelle: 'Rechercher un', model: 'status'                    , value: '', index: 'status'                    , datas: this.candidatureStatus, loading: this.$store.getters['jobDescription/loading'], options: { type: 'deroulant', fieldsKey: 'status'             } }
        ]
    }

    /**
     * @description Initialisation des colonnes du tableau
     * @returns {void}
     */
    setFieldsForGenericTab(): void {
        this.genericfields = []

        this.genericfields.push(
            { key: 'candidat.name',               label: 'Candidat',              sortable: true,  class: '',            type: 'actionText' },
            { key: 'jobDescription.name',         label: 'Intitulé',              sortable: true,  class: '',            type: 'actionText' },
            { key: 'jobDescription.description',  label: 'Description',           sortable: false, class: '',            type: 'text'       },
            { key: 'working_establishment_name',  label: 'Établissement',         sortable: true,  class: '',            type: 'text'       },
            { key: 'status',                      label: 'Statut',                sortable: true,  class: 'text-center', type: 'text'       },
            { key: 'updated_at',                  label: 'Dernière modification', sortable: true,  class: 'ps-5'                            },
            { key: 'etatEdit',                    label: '',                      sortable: false, class: '',            type: 'action'     }
        )

        if (this.isInDetailEstablishment()) {
            this.genericfields.splice(3, 1)
        }
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'showCandidat':
                    this.openCandidatModal(paParams[1])
                    break

                case 'showJobDescription':
                    await this.openJobModal(paParams[1])
                    break

                case 'editStatus':
                    if (this.canEdit()) {
                        await this.openEditStatus(paParams[1][0].result)
                    }
                    break

                case 'openEtablissement':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1]}`)
                    break

                case 'showCandidature':
                    await this.$router.push(`/bourse_emploi/candidatures/${paParams[1][0].result}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'popupHistorique':
                    this.openPopupHistoriqueJobDescription(paParams[1][0].item.id)
                    break
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            if (this.isInDetailEstablishment()) {
                params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
            }
            localStorage.setItem('candidatureParams', JSON.stringify(params))
            await this.$store.dispatch('candidatJobDescription/getCandidatJobDescriptions', params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (this.isInDetailEstablishment()) {
            params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        localStorage.setItem('candidatureParams', JSON.stringify(params))
        await this.$store.dispatch('candidatJobDescription/getMoreCandidatJobDescriptions', params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        this.filtreJustInitiated = true

        await this.loadDatas()
        this.setFieldsForGenericTab()
        this.setFiltersForGenericTab()
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        (window as any).handleTableEvent = this.handleTableEvent
    }

    /**
     * @description Avant la destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        (window as any).handleTableEvent = null
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
