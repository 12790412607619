

































































































































import Back from '@/components/Tools/Back.vue'
import { ConcourInterface } from '@/types/Concour'
import { isObject } from '@/utils/helpers'
import formulaire from '@exatech-group/formulaire/src/Formulaire.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
    components: {
        formulaire,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('session', ['sessionActive']),
        ...mapGetters('jobProfileForm', ['jobProfileFormSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class EditionFormulaireProfil extends Vue {
    jobProfileFormTemp: any = null
    showModalValideFormulaire = false
    requestTitle = false
    processing = false

    /**
     * storeDocumentReference
     * Cette fonction permet de stocker un document référence dans le formulaire en cours.
     * @param {string} titre - Titre du document
     * @param {any} data - Données du document à stocker
     * @returns une promesse résolue avec l'UUID du document stockée
     */
    async storeDocumentReference(titre: string, data: any) {
        const formdata = new FormData()
        formdata.set('document', data)
        formdata.set('name', titre)
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobProfileForm/storeDocumentReference', {
                    payload: formdata,
                    jobProfileForm_id: this.jobProfileFormTemp.id
                })
                .then((response: any) => {
                    resolve({ id: response.data.data.uuid })
                })
        })
    }

    /**
     * getDocumentReference
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à récupérer
     * @returns une promesse résolue avec le document récupéré
     */
    getDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobProfileForm/getDocumentReference', {
                    jobProfileForm_id: this.jobProfileFormTemp.id,
                    documentId: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * deleteDocumentReference
     * Cette fonction permet de supprimer un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à supprimer
     * @returns une promesse résolue avec la réponse de la suppression du document
     */
    deleteDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobProfileForm/deleteDocumentReference', {
                    jobProfileForm_id: this.jobProfileFormTemp.id,
                    documentId: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * updateFormulaire
     * Cette fonction met à jour le formulaire avec les valeurs fournies.
     * @param {any} value - Nouvelle valeur du formulaire
     */
    updateFormulaire(value: any) {
        if (this.$store.state.jobProfileForm.jobProfileFormSelect === null) {
            this.jobProfileFormTemp = {
                id: null,
                session_id: this.$store.state.session.sessionSelect.id,
                name: '',
                datas: null
            }
        }
        this.jobProfileFormTemp.name = JSON.parse(value).libelle
        this.jobProfileFormTemp.datas = value
    }

    /**
     * saveFormulaire
     * Cette fonction permet d'enregistrer ou de mettre à jour un formulaire.
     */
    saveFormulaire() {
        if (this.processing) {
            return
        }
        this.processing = true
        this.jobProfileFormTemp.name = this.jobProfileFormTemp.datas.libelle

        const payload: any = {
            id: this.jobProfileFormTemp.id,
            name: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas.libelle
                : JSON.parse(this.jobProfileFormTemp.datas).libelle,
            datas: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas
                : JSON.parse(this.jobProfileFormTemp.datas),
            session_id: this.jobProfileFormTemp.session_id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch(`jobProfileForm/${this.jobProfileFormTemp.id ? 'update' : 'add' }JobProfileForm`, payload)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifié avec succès !', succesToaster)

                this.requestTitle = false

                this.$store.state.jobProfileForm.jobProfileFormSelect = response.data.data
                this.jobProfileFormTemp.id = response.data.data.id
                this.updateFormulaire(response.data.data.datas)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * valideFormulaire
     * Cette fonction ouvre une fenêtre modale pour valider le formulaire.
     */
    valideFormulaire() {
        this.showModalValideFormulaire = true
    }

    /**
     * cancelValideFormulaire
     * Cette fonction annule la validation du formulaire en fermant la fenêtre modale.
     */
    cancelValideFormulaire() {
        this.showModalValideFormulaire = false
    }

    /**
     * valideFormulaireSuite
     * Cette fonction permet de valider le formulaire en cours.
     */
    valideFormulaireSuite() {
        this.jobProfileFormTemp.name = this.jobProfileFormTemp.datas.libelle

        const payload = {
            id: this.jobProfileFormTemp.id,
            name: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas.libelle
                : JSON.parse(this.jobProfileFormTemp.datas).libelle,
            datas: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas
                : JSON.parse(this.jobProfileFormTemp.datas),
            validate: this.jobProfileFormTemp.validated_at ? 0 : 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('jobProfileForm/updateJobProfileForm', payload)
            .then((response: any) => {
                this.jobProfileFormTemp.validated_at = response.data.data.validated_at
                this.$store.state.jobProfileForm.jobProfileFormSelect =  response.data.data
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifiée avec succès !', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.showModalValideFormulaire = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * beforeMount
     * Cette fonction est exécutée avant que le composant ne soit monté.
     */
    beforeMount() {
        this.$store.dispatch('jobProfileForm/getJobProfileForms').then(() =>  {
            if (this.$store.state.jobProfileForm.jobProfileForms.length > 0) {
                this.jobProfileFormTemp = this.$store.state.jobProfileForm.jobProfileForms[0]
                this.$store.state.jobProfileForm.jobProfileFormSelect = this.$store.state.jobProfileForm.jobProfileForms[0]
            } else {
                this.jobProfileFormTemp = {
                    id: null,
                    session_id: this.$store.state.session.sessionSelect.id,
                    name: '',
                    datas: {
                        libelle: '',
                        description: '',
                        parties: []
                    }
                }
                this.requestTitle = true
            }
        })
    }
}
