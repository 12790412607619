










































































































































































































































































































































import ActivityLog from '@/components/ActivityLog.vue'
import PopupEditUser from '@/components/Administration/Users/PopupEditUser.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { Ability } from '@/types/Ability'
import { getJobDescriptionStatus, JobDescriptionInterface, JobDescriptionStatus } from '@/types/JobDescription'
import {
    base64ToArrayBuffer,
    convertDateLocalValeurT,
    dateisSameOrBefore,
    formatDateVariante,
    formatDayHourZDateFromString,
    getFileNameFromHeader
} from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
    methods: {
        getJobDescriptionStatus
    },
    components: {
        ExaGenericTable,
        PopupEditUser,
        'font-awesome-icon': FontAwesomeIcon,
        'activity-log': ActivityLog,
        VuePdfApp,
        ErrorDisplay,
        EditorTinyMCE,
        PopupEditCandidat
    },
    computed: {
        Ability() {
            return Ability
        },
        JobDescriptionStatus() {
            return JobDescriptionStatus
        },
        ...mapGetters('jobDescription', ['jobDescriptions', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error']),
        ...mapGetters('auth', ['can'])
    }
})

export default class ListePostes extends Vue {
    filtres: any = []
    filtreJustInitiated = false
    dataForTab: Array<any> = []
    genericfields: Array<any> = []
    showUpdateJobModal = false
    showRemoveJobModal = false
    showEditJobStatus = false
    showCandidatModal = false
    showJobDocument = false
    jobToRemove: any = null
    jobToUpdate: any = null
    jobDocuments: Array<any> = []
    jobLoading = false
    jobStatus: any = null
    jobCurrentStatus: any = null
    showModalParametrages = false
    sessionEnCours: any = null
    readOnly = false
    showEditors = false
    resizeObserver: any = null
    maxEditorsValuesLength = 5000
    editorsValues: any = {
        description: null,
        missions: null,
        required_profile: null,
        contact: null
    }

    jobSaisie: any = {
        start_at: null,
        end_at: null
    }

    documentToShow: any = {
        name: null,
        content: null
    }

    params: any = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    }

    config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }


    // ------historique
    formatDateVariante = formatDateVariante

    togglePopupHistoriqueJobDescription = false
    errorKeeper = null
    lastSave: any = null
    historiqueJobDescription: any = null

    /**
     * Toggle le popup d'historique des validations / invalidations des barres
     * @returns {void}
     */
    openPopupHistoriqueJobDescription(jobDescription_id: any): void {
        if (this.isInDetailEstablishment()) {
            return
        }

        this.errorKeeper = null
        this.historiqueJobDescription = null
        this.$store.dispatch('jobDescription/getActivityLog', { jobDescription_id: jobDescription_id })
            .then((response) => {
                this.historiqueJobDescription = response.data.data
                this.togglePopupHistoriqueJobDescription = true
            })
    }

    closePopupHistoriqueJobDescription(): void {
        this.historiqueJobDescription = null
        this.togglePopupHistoriqueJobDescription = false
    }

    // -----------------

    /**
     * @description Vérifie si l'utilisateur peut éditer
     * @param {JobDescriptionInterface} job - Fiche de poste
     * @returns {boolean}
     */
    canEdit(job?: JobDescriptionInterface, status = false): boolean {
        const can = this.$store.getters['auth/can']
        if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
            return true
        }
        if (can(Ability.INTERV_OWN_ESTABLISHMENT)) {
            if (!status) {
                if (job && !this.isDraft(job)) {
                    return false
                }
            }
            const session = this.$store.getters['session/sessions']
                .find((session: any) => session.id === this.$store.getters['auth/user_session_id'])
            const startAt = session.job_descriptions_start_at
            const endAt = session.job_descriptions_end_at

            if (startAt && endAt) {
                const start = new Date(startAt)
                const end = new Date(endAt)
                const now = new Date()

                if (now >= start && now <= end) {
                    return true
                }
            }
        }
        return false
    }

    /**
     * @description Vérifie si la fiche de poste est en brouillon
     * @param {any} job - Fiche de poste
     * @returns {boolean}
     */
    isDraft(job: any): boolean {
        return job?.status === JobDescriptionStatus.STATUS_DRAFT
    }

    /**
     * @description Vérifie si le texte dépasse la limite de caractères
     * @returns {boolean}
     */
    isTextOverflow(): boolean {
        return Object.keys(this.$data.editorsValues).some((key) => {
            return this.$data.editorsValues[key]?.length > this.maxEditorsValuesLength
        })
    }

    /**
     * @description Mise à jour du tableau des fiches de poste
     * @returns {void}
     */
    @Watch('jobDescriptions')
    onJobDescriptionsChange(): void {
        this.setDataForGenericTab(this.$store.getters['jobDescription/jobDescriptions'])
    }

    /**
     * @description Ouvre la modale de consultation des informations du candidat
     * @param {any} candidat - Candidat à consulter
     * @returns {void}
     */
    openCandidatModal(candidat: any): void {
        this.$store.commit('candidatJobDescription/SET_LOADING', true)
        this.$store.dispatch('candidat/getCandidat', { id: candidat.id })
            .then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                this.showCandidatModal = true
            })
            .finally(() => {
                this.$store.commit('candidatJobDescription/SET_LOADING', false)
            })
    }

    /**
     * @description Fermeture de la modale de consultation des informations du candidat
     * @returns {void}
     */
    closeCandidatModal() {
        this.showCandidatModal = false
    }

    /**
     * @description Ouverture de la modale d'ajout de fiche de poste
     * @param {any} job - Fiche de poste à modifier
     * @returns {Promise<void>}
     */
    async openJobModal(job?: any): Promise<void> {
        this.$store.commit('jobDescription/SET_ERROR', null)

        if (job) {
            await this.$store.dispatch('jobDescription/getJobDescription', { jobDescription_id: job.id })
            this.jobToUpdate = JSON.parse(JSON.stringify(this.$store.getters['jobDescription/jobDescriptionSelect']))
        } else {
            this.jobToUpdate ={
                name: '',
                description: '',
                missions: '',
                required_profile: '',
                contact: '',
                status: JobDescriptionStatus.STATUS_DRAFT,
                working_establishment_id: this.$route.params?.working_establishment_id || null,
                documents: []
            }
        }

        this.jobDocuments = this.jobToUpdate.documents || []
        this.jobCurrentStatus = JSON.parse(JSON.stringify(this.jobToUpdate.status))
        this.editorsValues = {
            description: this.jobToUpdate.description,
            missions: this.jobToUpdate.missions,
            required_profile: this.jobToUpdate.required_profile,
            contact: this.jobToUpdate.contact
        }
        this.showUpdateJobModal = true
    }

    /**
     * @description Fermeture de la modale d'ajout de fiche de poste
     * @returns {void}
     */
    closeJobModal(): void {
        this.showUpdateJobModal = false
        this.jobCurrentStatus = null
        this.jobDocuments = []
        this.jobToUpdate = null
        this.readOnly = false
        this.editorsValues = {
            description: null,
            missions: null,
            required_profile: null,
            contact: null
        }
        this.$store.commit('jobDescription/SET_ERROR', null)
    }

    /**
     * @description Enregistre les modifications de l'éditeur
     * @param {{bloc_id: string; content: string}} event - Événement de modification
     */
    setEditorValue(event: {bloc_id: string; content: string}) {
        this.editorsValues[event.bloc_id.slice(4)] = event.content
    }

    /**
     * @description Ajout / Mise à jour de la fiche de poste
     * @returns {void}
     */
    updateJob(): void {
        if (this.jobLoading || this.isTextOverflow() || !this.editorsValues.contact || !this.jobToUpdate.name) {
            return
        }
        this.jobLoading = true

        this.jobToUpdate = {
            ...this.jobToUpdate,
            description: this.editorsValues.description || this.jobToUpdate.description,
            missions: this.editorsValues.missions || this.jobToUpdate.missions,
            required_profile: this.editorsValues.required_profile || this.jobToUpdate.required_profile,
            contact: this.editorsValues.contact || this.jobToUpdate.contact,
            documents: this.jobDocuments.map((document: any) => document.uuid),
            status: this.jobCurrentStatus
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch(`jobDescription/${this.jobToUpdate.id ? 'update' : 'add'}JobDescription`, this.jobToUpdate)
            .then(async (response) => {
                const jobId = response.data.data.id

                if (this.jobDocuments.length > 0) {
                    const promises = []
                    for (const document of this.jobDocuments) {
                        promises.push(this.$store.dispatch('jobDescription/addJobDocument', {
                            job_id: jobId, document: document
                        }))
                    }

                    await Promise.all(promises)
                }

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                if (this.isInDetailEstablishment()) {
                    this.params.working_establishment_id = this.$route.params.working_establishment_id
                }
                await this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
            })
            .finally(() => {
                this.closeJobModal()
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouverture de la modale de suppression de fiche de poste
     * @returns {void}
     */
    openRemoveJobModal(job: any): void {
        this.jobToRemove = job
        this.showRemoveJobModal = true
    }

    /**
     * @description Fermeture de la modale de suppression de fiche de poste
     * @returns {void}
     */
    closeRemoveJobModal(): void {
        this.showRemoveJobModal = false
        this.jobToRemove = null
    }

    /**
     * @description Suppression de la fiche de poste
     * @returns {void}
     */
    removeJob(): void {
        if (this.jobLoading) {
            return
        }
        this.jobLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        this.$store.dispatch('jobDescription/deleteJobDescription', this.jobToRemove.id)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                if (this.isInDetailEstablishment()) {
                    this.params.working_establishment_id = this.$route.params.working_establishment_id
                }
                this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
            })
            .catch(() => {
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id: idError,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Une erreur est survenue.', errorToaster)
            })
            .finally(() => {
                this.closeRemoveJobModal()
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouverture de la modale de consultation d'un document de la fiche de poste
     * @param {any} job - Fiche de poste
     * @param {number} index - Index du document
     * @returns {Promise<void>}
     */
    async openJobDocument(job: any, index: number): Promise<void> {
        this.documentToShow.name = job.documents[index].name
        const response = await this.$store.dispatch('jobDescription/getJobDocument', {
            job_id: job.id, document_uuid: job.documents[index].uuid
        })
        this.documentToShow.content = base64ToArrayBuffer(response.data)
        this.showJobDocument = true
    }

    /**
     * @description Fermeture de la modale de consultation d'un document de la fiche de poste
     * @returns {void}
     */
    closeJobDocument(): void {
        this.showJobDocument = false
        this.documentToShow = {
            name: null,
            content: null
        }
    }

    /**
     * @description Ajout d'un document à la fiche de poste
     * @returns {void}
     */
    addFile(): void {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'application/pdf'
        input.onchange = this.onFileChange
        input.click()
        input.remove()
    }

    /**
     * @description Gestion de l'ajout de fichier
     * @param {any} event - Événement de changement de fichier
     * @returns {void}
     */
    onFileChange(event: any): void {
        this.jobDocuments.push(event.target.files[0])
    }

    /**
     * @description Suppression d'un document de la fiche de poste
     * @param {number} index - Index du document à supprimer
     * @returns {void}
     */
    removeFile(index: number): void {
        this.jobDocuments.splice(index, 1)
    }

    /**
     * @description Ouverture de la modale d'édition du statut de la fiche de poste
     * @param {any} job - Fiche de poste à modifier
     * @returns {void}
     */
    openEditJobStatus(job: any): void {
        this.jobToUpdate = JSON.parse(JSON.stringify(job))
        this.jobCurrentStatus = JSON.parse(JSON.stringify(this.jobToUpdate.status))
        this.showEditJobStatus = true
    }

    /**
     * @description Fermeture de la modale d'édition du statut de la fiche de poste
     * @returns {void}
     */
    closeEditJobStatus(): void {
        this.showEditJobStatus = false
        this.jobToUpdate = null
        this.jobCurrentStatus = null
    }

    /**
     * @description Mise à jour du statut de la fiche de poste
     * @param {JobDescriptionStatus | null} status - Statut de la fiche de poste
     * @returns {void}
     */
    updateJobStatus(status: JobDescriptionStatus | null = null): void {
        if (this.jobLoading) {
            return
        }
        this.jobLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('jobDescription/updateStatus', {
            job_id: this.jobToUpdate.id, status: status === 0 ? 0 : status || this.jobCurrentStatus
        })
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                await this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
                if (status) {
                    await this.openJobModal(
                        this.$store.getters['jobDescription/jobDescriptions']
                            .find((job: any) => job.id === this.jobToUpdate.id)
                    )
                }
            })
            .finally(() => {
                this.closeEditJobStatus()
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    openParametrage () {
        this.jobSaisie = {
            start_at: null,
            end_at: null
        }

        this.sessionEnCours = this.$store.state.session.sessions.find((s: any) => s.id === this.$store.getters['auth/user_session_id'])
        if (this.sessionEnCours.job_descriptions_start_at) {
            this.jobSaisie.start_at = convertDateLocalValeurT(this.sessionEnCours.job_descriptions_start_at)
            this.jobSaisie.end_at = convertDateLocalValeurT(this.sessionEnCours.job_descriptions_end_at)
        }
        this.showModalParametrages = true
    }

    closeModalParametrages () {
        this.showModalParametrages = false
    }

    saveParametrage () {
        const payload = {
            job_descriptions: 1,
            job_descriptions_start_at: formatDayHourZDateFromString(this.jobSaisie.start_at),
            job_descriptions_end_at: formatDayHourZDateFromString(this.jobSaisie.end_at)
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('session/updateSessionPlages', payload)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.$store.commit('session/UPDATE_SESSION', response.data.data)
                this.closeModalParametrages()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Exportation des fiches de poste
     * @returns {void}
     */
    exportJobs(): void {
        if (this.jobLoading) {
            return
        }
        this.jobLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours ...', infosToaster)

        this.$store.dispatch('jobDescription/export')
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || 'postes.xlsx'
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Attends le rafraichissement complet du navigateur
     * @return {Promise<void>}
     */
    waitRequestAnimationFrame(): Promise<void> {
        return new Promise((resolve) => {
            this.$nextTick(() => {
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => {
                        resolve()
                    })
                })
            })
        })
    }

    toggleResizeObserver(element: any): void {
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(this.movePlaceholders)
        }

        if (element && this.showEditors) {
            this.resizeObserver.observe(element)
        } else {
            this.resizeObserver.disconnect()
        }
    }

    /**
     * @description Déplacement des placeholders
     * @returns {Promise<void>}
     */
    @Watch('editorsValues', { deep: true })
    async movePlaceholders(): Promise<void> {
        await this.waitRequestAnimationFrame()
        const placeholders: HTMLCollectionOf<Element> = document.getElementsByClassName('placeholder')
        const editorHeader: Element = document.getElementsByClassName('mce-top-part')[0]
        if (!placeholders || !editorHeader) {
            return
        }

        for (const placeholder of placeholders) {
            (placeholder as HTMLElement).style.transform = `translate(10px, ${editorHeader.clientHeight + 16}px)`
        }
    }

    /**
     * @description Tronquer une chaîne de caractères
     * @param {string} str - Chaîne de caractères à tronquer
     * @param {number} length - Longueur maximale de la chaîne
     * @returns {string} - Chaîne tronquée
     */
    sliceString(str: string, length = 100): string {
        if (str) {
            const temp = document.createElement('div')
            temp.innerHTML = str
            str = temp.textContent || str
            temp.remove()
            str = str.replace(/\s\s+/g, ' ')

            return str.length > length ? `${str.slice(0, length)}...` : str
        }
        return '-'
    }

    /**
     * @description Remplissage du tableau des fiches de poste
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const status = getJobDescriptionStatus(result.status)
                let statusTitle = status.name
                if (status.index === JobDescriptionStatus.STATUS_PUBLISHED && result.published_at) {
                    statusTitle += ` le ${formatDateVariante(result.published_at)}`
                } else if (status.index === JobDescriptionStatus.STATUS_PROVIDED && result.provided_at) {
                    statusTitle += ` le ${formatDateVariante(result.provided_at)}`
                }
                const puceStatus = [{ name: 'circle', class: `text-${status.color}`, title: statusTitle, result: result }]

                const establishment = `<button type="button" class="m-0 p-0 text-info btn-establishment ${this.$route.path === `/bourse_emploi/etablissements/${result.workingEstablishment.id}` ? 'pe-none' : ''}" onclick="handleTableEvent(['openEtablissement', ${result.workingEstablishment.id}])">${result.workingEstablishment.name}</button>`

                const documents = result.documents.map((document: any, index: number) => {
                    return { name: 'file-alt', class: 'text-info cursor-pointer', title: document.name, result: { job: result, index: index }}
                })

                const datas: any = []
                if (this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE) || this.$store.getters['auth/can'](Ability.INTERV_OWN_ESTABLISHMENT)) {
                    datas.push({ label: 'Éditer', item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: this.canEdit(result) && this.isDraft(result) ? 'pen' : 'eye', disabled: false })
                    if (this.canEdit(result) && this.isDraft(result)) {
                        datas.push({ label: 'Voir', item: result, type: 'action', typeAction: 'show', class: 'commons_first_action_button', icon: 'eye', disabled: false })
                    } else {
                        datas.push({ label: '', item: null, type: null, typeAction: null, class: 'commons_first_action_button', icon: null, disabled: true })
                    }
                }

                datas.push(
                    { label: '', item: result.name,                               type: 'text',  typeAction: null,           class: ''                                                },
                    { label: '', item: this.sliceString(result.description),      type: 'html',  typeAction: null,           class: 'one-line'                                        },
                    { label: '', item: this.sliceString(result.required_profile), type: 'html',  typeAction: null,           class: 'one-line'                                        },
                    { label: '', item: establishment,                             type: 'html',  typeAction: null,           class: ''                                                },
                    { label: '', item: documents,                                 type: 'icons', typeAction: 'showDocument', class: ''                                                },
                    { label: '', item: result.candidacies_count,                  type: 'text',  typeAction: null,           class: 'text-center'                                     },
                    { label: '', item: puceStatus,                                type: 'icons', typeAction: 'editStatus',   class: `text-center ${!this.canEdit() ? 'pe-none' : ''}` }
                )

                if((this.lastSave === null && result.updated_at) || (this.lastSave && result.updated_at && dateisSameOrBefore(this.lastSave, result.updated_at))) {
                    this.lastSave = result.updated_at
                }
                const updatedAt = (result.updated_at ? formatDateVariante(result.updated_at) : ' - ')
                const updatedAtIcon = [{
                    name: 'clock',
                    class: 'text-info text-small text-start',
                    title: updatedAt,
                    value_comp: updatedAt,
                    typeAction: 'popupHistorique',
                    item: result
                }]
                datas.push({ label: '', item: updatedAtIcon, type: 'icons', typeAction: 'popupHistorique', class: 'text-center' })

                if (this.isInDetailEstablishment()) {
                    datas.splice(5, 1)
                }

                if (this.isInDetailEstablishment() && this.canEdit()) {
                    datas.push({ label: 'Supprimer', item: result, type: 'action', typeAction: 'remove', class: 'text-secondary text-end', icon: 'trash-alt', disabled: result.status !== 0 })
                }

                this.dataForTab.push(datas)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        const middle = Math.floor(Object.keys(JobDescriptionStatus).length / 2)
        this.jobStatus = Object.keys(JobDescriptionStatus).slice(0, middle)
            .map(status => getJobDescriptionStatus(parseInt(status)))

        this.filtres = [
            { libelle: 'Intitulé', defautOptionlibelle: 'Rechercher un',  model: 'name', value: '', index: 'intitule', datas: null,           loading: this.$store.getters['jobDescription/loading'], options: { type: 'form',      fieldsKey: 'name' } },
            { libelle: 'Statut',   defautOptionlibelle: 'Rechercher un',  model: 'status',   value: '', index: 'status',   datas: this.jobStatus, loading: this.$store.getters['jobDescription/loading'], options: { type: 'deroulant', fieldsKey: 'status'   } }
        ]
    }

    /**
     * @description Initialisation des colonnes du tableau
     * @returns {void}
     */
    setFieldsForGenericTab(): void {
        this.genericfields = []

        if (this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE) || this.$store.getters['auth/can'](Ability.INTERV_OWN_ESTABLISHMENT)) {
            this.genericfields.push({ key: 'edit', label: '', sortable: false, class: '', type: 'action' })
            this.genericfields.push({ key: 'show', label: '', sortable: false, class: '', type: 'action' })
        }

        this.genericfields.push(
            { key: 'name',                      label: 'Intitulé',              sortable: true,  class: '',            type: 'text' },
            { key: 'description',               label: 'Description',           sortable: false, class: '',            type: 'text' },
            { key: 'required_profile',          label: 'Profil recherché',      sortable: false, class: '',            type: 'text' },
            { key: 'workingEstablishment.name', label: 'Établissement',         sortable: true,  class: '',            type: 'text' },
            { key: 'documents',                 label: 'Documents',             sortable: false, class: 'text-center', type: 'text' },
            { key: 'candidatures',              label: 'Candidatures',          sortable: true,  class: 'text-center', type: 'text' },
            { key: 'status',                    label: 'Statut',                sortable: true,  class: 'text-center', type: 'text' },
            { key: 'updated_at',                label: 'Dernière modification', sortable: true,  class: 'ps-5' }
        )

        if (this.isInDetailEstablishment()) {
            this.genericfields.splice(5, 1)
        }

        if (this.isInDetailEstablishment() && this.canEdit()) {
            this.genericfields.push({ key: 'remove', label: '', sortable: false, class: 'text-end', type: 'action' })
        }
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    if (this.canEdit()) {
                        await this.openJobModal(paParams[1])
                    }
                    break

                case 'show':
                    this.readOnly = true
                    await this.openJobModal(paParams[1])
                    break

                case 'showDocument':
                    await this.openJobDocument(
                        paParams[1][paParams[2]].result.job,
                        paParams[1][paParams[2]].result.index
                    )
                    break

                case 'editStatus':
                    if (this.canEdit()) {
                        await this.openEditJobStatus(paParams[1][0].result)
                    }
                    break

                case 'remove':
                    if (this.canEdit()) {
                        await this.openRemoveJobModal(paParams[1])
                    }
                    break

                case 'openEtablissement':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1]}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break
                case 'popupHistorique':
                    this.openPopupHistoriqueJobDescription(paParams[1][0].item.id)
                    break
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            if (this.isInDetailEstablishment()) {
                params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
            }
            this.params = params
            await this.$store.dispatch('jobDescription/getJobDescriptions', params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (this.isInDetailEstablishment()) {
            params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        this.params = params
        await this.$store.dispatch('jobDescription/getMoreJobDescriptions', params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        this.filtreJustInitiated = true
        if (this.isInDetailEstablishment()) {
            this.params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        await this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
        this.setFieldsForGenericTab()
        this.setFiltersForGenericTab()
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        (window as any).handleTableEvent = this.handleTableEvent
    }

    /**
     * @description Calcul le placement des placeholders en fonction de la hauteur du header de l'éditeur
     * @returns {void}
     */
    updated(): void {
        this.waitRequestAnimationFrame()
            .then(() => {
                this.toggleResizeObserver(document.getElementsByClassName('mce-top-part')[0])
            })
    }

    /**
     * @description Avant la destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect()
        }
        (window as any).handleTableEvent = null
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
