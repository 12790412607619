































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch, Prop }  from 'vue-property-decorator'
import { mapGetters, mapState }   from 'vuex'
import { Ability }                from '@/types/Ability'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'
import { Etat } from '@/types/DossierAcademique'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('user', ['dossieracademiqueId', 'dossieradministratifId']),
        ...mapState('dossieracademique', ['dossierAcademiqueSelect', 'loading']),
        ...mapState('gabaritFicheHoraire', ['centre_user_fh']),
        ...mapState('user', ['has_poste_aca'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class TDBExaminateur extends Vue {
    @Prop() hasContrats?: boolean

    ability             = Ability
    tuiles: any  = []
    info: any          = []
    centre_select: any = null
    color_signature_fiche_horaire = 'text-primary'

    @Watch('centre_user_fh')
    initColor () {
        // Couleur de l'avancée de signature de la fiche horaire
        this.color_signature_fiche_horaire = 'text-primary'
        if (this.$store.state.gabaritFicheHoraire.centre_user_fh !== null) {
            for (const p in this.$store.state.gabaritFicheHoraire.centre_user_fh.postes) {
                if (this.$store.state.gabaritFicheHoraire.centre_user_fh.postes[p].fiche_signed_at) {
                    this.color_signature_fiche_horaire = 'text-success'
                } else {
                    this.color_signature_fiche_horaire = 'text-primary'
                }
            }
            this.createPreparationDashboard()
        }
    }

    @Watch('dossieracademiqueId')
    @Watch('dossieradministratifId')
    reloadTuiles () {
        this.createPreparationDashboard()
    }

    @Watch('has_poste_aca')
    refresTuiles () {
        if (this.$store.state.user.has_poste_aca) {
            this.createPreparationDashboard()
        }
    }


    /**  creer un objet de deux tableaux (box1, box2) correspondant aux deux colonnes d'affichage. */
    createPreparationDashboard() {
        let color_dossier_aca = 'text-info'
        const da = this.$store.getters['dossieracademique/dossierAcademiqueByUserId'](this.$store.getters['auth/authUser'].id)
        switch (da && da.courant && da.courant.etat) {
            case Etat.ETAT_VIERGE:
                color_dossier_aca = 'text-primary'
                break
            case Etat.ETAT_DEPOSE:
                color_dossier_aca = 'text-info'
                break
            case Etat.ETAT_VALIDE:
                color_dossier_aca = 'text-success'
                break
            case Etat.ETAT_REJETE:
                color_dossier_aca = 'text-danger'
                break
        }

        let color_dossier_admin = 'text-info'
        switch (this.$store.state.dossieradministratif.dossiersAdministratifs.length !== 0 && this.$store.state.dossieradministratif.dossiersAdministratifs[0].etat) {
            case Etat.ETAT_VIERGE:
                color_dossier_admin = 'text-primary'
                break
            case Etat.ETAT_DEPOSE:
                color_dossier_admin = 'text-info'
                break
            case Etat.ETAT_VALIDE:
                color_dossier_admin = 'text-success'
                break
            case Etat.ETAT_REJETE:
                color_dossier_admin = 'text-danger'
                break
        }

        const aides = this.$store.getters['auth/findParameter']('aides')
        const ordreMissionDisabled = this.$store.getters['auth/findParameter']('ordreMissionDisabled')
        this.tuiles = {
            box1: [
                {
                    name: 'Besoin d\'aide ?',
                    icone: 'life-ring',
                    tooltip : null,
                    permissions: aides && (aides.value?.videos?.length > 0 || aides.value?.documents?.length > 0) ? [true] : [false],
                    key: 'aide',
                    informations: [
                        {
                            description: 'TUTORIELS VIDEOS',
                            etat: 'text-info',
                            key: 'aide',
                            link: '/aide',
                            permissions: aides && (aides.value?.videos?.length > 0 || aides.value?.documents?.length > 0) ? [true] : [false]
                        }
                    ]
                },
                {
                    name: 'Mon dossier académique',
                    icone: 'graduation-cap',
                    tooltip : null,
                    permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE]  : [false],
                    informations: [
                        {
                            description: 'REMPLIR MON DOSSIER DE CANDIDATURE',
                            etat: color_dossier_aca,
                            link: '/dossier_academique/' + this.$store.state.user.dossieracademiqueId,
                            permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE]  : [false]
                        }
                    ]
                },
                {
                    name: 'Mon dossier administratif',
                    icone: 'folder-open',
                    tooltip : null,
                    permissions: this.$store.state.user.dossieradministratifId !== null ? [Ability.RH_ADM_DOS_OWN_MANAGE]  : [false],
                    informations: [
                        {
                            description: 'REMPLIR MON DOSSIER ADMINISTRATIF',
                            etat: color_dossier_admin,
                            link: '/dossier_administratif/' + this.$store.state.user.dossieradministratifId,
                            permissions: this.$store.state.user.dossieradministratifId !== null ? [Ability.RH_ADM_DOS_OWN_MANAGE]  : [false]
                        }
                    ]
                },
                {
                    name: 'Groupes d\'intervenants',
                    icone: 'graduation-cap',
                    tooltip : null,
                    permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE, Ability.RH_ADM_DOS_OWN_MANAGE]  : [false],
                    informations: [
                        {
                            description: 'VOIR LES GROUPES D\'INTERVENANTS',
                            etat: 'text-info',
                            link: '/groupes_intervenants',
                            permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE, Ability.RH_ADM_DOS_OWN_MANAGE]  : [false]
                        }
                    ]
                },
                {
                    name: 'Bourse à l\'emploi',
                    icone: 'handshake',
                    tooltip : null,
                    permissions: [Ability.INTERV_OWN_ESTABLISHMENT],
                    informations: [
                        {
                            description: 'Établissement(s)',
                            etat: 'text-info',
                            link: '/bourse_emploi/etablissements',
                            permissions: [Ability.INTERV_OWN_ESTABLISHMENT]
                        }
                    ]
                },
                {
                    name: 'Gestion des contrats',
                    icone: 'file-contract',
                    tooltip : null,
                    permissions: this.hasContrats ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false],
                    informations: [
                        {
                            description: 'CONTRATS & AVENANTS',
                            etat: 'text-primary',
                            link: '/contrats_intervenant',
                            permissions: [Ability.RH_ADM_DOS_OWN_MANAGE]
                        },
                        {
                            description: 'ORDRES DE MISSIONS',
                            etat: 'text-primary',
                            link: '/ordres_mission_intervenant',
                            permissions: this.$store.state.user.has_poste_aca && this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1' ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false]
                        },
                        {
                            description: 'DEMANDES DE REMBOURSEMENT',
                            etat: 'text-primary',
                            link: '/demandes_remboursement_intervenant',
                            permissions: this.$store.state.user.has_poste_aca && this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1' ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false]
                        }
                    ]
                }

            ],
            box2: [
                {
                    name: 'Fiche horaire',
                    icone: 'watch',
                    tooltip: null,
                    permissions: [Ability.RH_FICHEHORAIRE_OWN_SIGN],
                    informations: [
                        {
                            description: 'SIGNER MA FICHE HORAIRE',
                            etat: this.color_signature_fiche_horaire,
                            link: '/signature_fiche_horaire',
                            permissions: [Ability.RH_FICHEHORAIRE_OWN_SIGN]
                        }
                    ]
                },
                {
                    name: 'Gestion des oraux',
                    icone: 'chalkboard-teacher',
                    tooltip: null,
                    permissions: [Ability.INTERV_PLANIF_OWN, Ability.INTERV_SUPPLEANT_OWN],
                    informations: [
                        {
                            description: 'Gérer mes incompatibilités',
                            etat: this.$store.state.sessionuser.sessionUser && this.$store.state.sessionuser.sessionUser.incompatibilites_submitted_at ? 'text-success' : 'text-primary',
                            link: 'gestion_incompatibles_examinateur',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Gérer mes disponibilités',
                            etat: this.$store.state.sessionuser.sessionUser && this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at ? 'text-success' : 'text-primary',
                            link: 'gestion_disponibilites_examinateur',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Consulter mon planning',
                            etat: 'text-info',
                            link: 'planning_examinateur',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Consulter mon planning',
                            etat: 'text-info',
                            link: 'planning_suppleant',
                            permissions: [Ability.INTERV_SUPPLEANT_OWN]
                        },
                        {
                            description: 'Liste des candidats avec aménagements',
                            etat: 'text-info',
                            link: 'liste_candidats_mesures',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Liste des candidats avec aménagements',
                            etat: 'text-info',
                            link: 'liste_candidats_mesures_supp',
                            permissions: [Ability.INTERV_SUPPLEANT_OWN]
                        },
                        {
                            description: 'LISTE DES CANDIDATS DÉMISSIONNAIRES',
                            etat: 'text-info',
                            link: '/liste_demissionnaires',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'LISTE DES CANDIDATS DÉMISSIONNAIRES',
                            etat: 'text-info',
                            link: '/liste_demissionnaires_supp',
                            permissions: [Ability.INTERV_SUPPLEANT_OWN]
                        },
                        {
                            description: 'Trombinoscope des examinateurs',
                            link: '/trombinoscope_examinateurs_ex',
                            etat: 'text-info',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Dépôt de fichier',
                            link: '/documents_examinateur',
                            etat: 'text-info',
                            permissions: [Ability.INTERV_PLANIF_OWN] // RH_SPS_DOS_OWN_MANAGE]
                        }
                    ]
                },
                {
                    name: 'Résultats aux concours',
                    icone: 'chart-bar',
                    tooltip : null,
                    permissions: [Ability.EXT_RESULTS_VIEW],
                    informations: [
                        {
                            description: 'CONSULTER LES RÉSULTATS AUX CONCOURS',
                            etat: 'text-info',
                            link: '/resultats',
                            permissions: [Ability.EXT_RESULTS_VIEW]
                        }
                    ]
                }
            ]
        }
    }

    // Retourne la couleur d'affichage du lien
    getColor(number1?: number | boolean, number2?: number) {
        if (!number2) {
            return number1 === true ? 'text-success' : 'text-secondary'
        } else {
            if (number1 === 0)
            {
                return 'text-secondary'
            } else if (number1 !== number2) {
                return 'text-info'
            } else if (number1 === number2) {
                return 'text-success'
            }
        }
    }

    // Check si l'utilisateur dispose de l'ability pour voir la tuile
    has_ability (permissions: any) {
        let has_ability = false

        for (const p in permissions) {
            if (this.$store.getters['auth/can'](permissions[p])) {
                has_ability = true
            }
        }
        return has_ability
    }

    async mounted () {
        // Chargement de la fiche horaire si les droits sont présents
        if (this.$store.getters['auth/can'](Ability.RH_FICHEHORAIRE_OWN_SIGN) && this.$store.state.user.centreId && this.$store.state.centre.centreSelect.fiches_intervenants_signed_at) {
            const response = await this.$store.dispatch('gabaritFicheHoraire/getFicheHoraire', { centreId: this.$store.state.user.centreId })
            this.$store.commit('gabaritFicheHoraire/SET_CENTRE_USER_FH', response.data.data)
            this.createPreparationDashboard()
        } else {
            if (!this.$store.getters['auth/is_intervenant_centre_nodirection']) {
                await this.$store.dispatch('sessionuser/getSessionUser', this.$store.state.auth.user.id)
            }
            this.createPreparationDashboard()
        }
    }
}
