



















































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PopupEditUser from '@/components/Administration/Users/PopupEditUser.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ContactCentre from '@/components/Centre/ContactCentre.vue'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        ErrorDisplay,
        ExaGenericTable,
        PopupEditUser,
        'contact-centre': ContactCentre,
        PhoneInput,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        Ability () {
            return Ability
        },
        ...mapGetters('user', ['loading', 'users', 'meta', 'totalRows', 'lastPage', 'totalPage', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class ListeUtilisateurs extends Vue {
    filtres: any = []
    filtreJustInitiated = false
    dataForTab: Array<any> = []

    // Ensemble des colonnes du tableau
    genericfields: any = []

    showImportUsers = false
    importFile: any = null
    importInProgress = false

    buildGenericfields () {
        this.genericfields = [
            { key: 'name',       label: 'Nom',       sortable: true,  class: '', type: 'text' },
            { key: 'first_name', label: 'Prénom',    sortable: true,  class: '', type: 'text' },
            { key: 'email',      label: 'Courriel',  sortable: true,  class: '', type: 'text' },
            { key: 'telephone',  label: 'Téléphone', sortable: false, class: '', type: 'text' }
        ]

        if (!this.isInDetailEstablishment()) {
            this.genericfields.push({ key: 'workingEstablishment.name', label: 'Établissement(s)', sortable: false, class: '', type: 'text' })
        }

        if (this.isInDetailEstablishment() && this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE)) {
            this.genericfields.push({ key: 'delete', label: '', sortable: false, class: 'text-end', type: 'action' })
        }
    }

    /**
     * @description Mise à jour du tableau des utilisateurs
     * @returns {void}
     */
    @Watch('users')
    onReportingUsersChange(): void {
        this.setDataForGenericTab(this.$store.getters['user/users'])
    }

    //--- ajout supression edition utilisateur ----

    params: any = null

    showSelectUserModal = false
    showEditValidationModal = false

    tabSelected = 'search'

    userInfo: any = {
        civility: null,
        name: null,
        first_name: null,
        email: null,
        telephone: '',
        telephone_fixe: ''
    }

    userSelected: any = null
    searching = false
    searchString = ''
    searchResults: Array<any> = []
    savingUserInfo = false
    showEditUserInfo = false

    userToRemove: any = null
    showModalMessageDelete = false

    delete (user: any) {
        console.log('remove: ', user)
        this.userToRemove = user
        this.showModalMessageDelete =  true
    }

    cancelDelete () {
        this.showModalMessageDelete =  false
        this.userToRemove = null
    }

    deleteSuite () {
        this.cancelDelete()
    }

    /**
     * Ouvre la modal de sélection d'un utilisateur
     * @returns {void}
     */
    openSelectUserModal(): void {
        this.showSelectUserModal = true
    }

    /**
     * Ouvre la modal de modification des permissions
     * @param {any} data - Données de l'utilisateur
     * @returns {void}
     */
    openEditValidationModal(data: any): void {
        if (this.showSelectUserModal) {
            this.closeSelectUserModal()
        }
        this.userSelected = data
        this.showEditValidationModal = true
    }

    updateValidations() {
        this.savingUserInfo = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('user/addReportingUser', { user_id: this.userSelected.id, working_establishment_id: this.$route.params?.working_establishment_id || null })
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.userSelected = response.data.data
                this.filtreSortHandler(this.params)
                this.closeEditUserInfo()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.savingUserInfo = false
                this.showSelectUserModal = false
                this.showEditValidationModal = false
            })
    }

    /**
     * Ferme la modal de modification des permissions
     * @returns {void}
     */
    closeEditValidationModal(): void {
        this.showEditValidationModal = false
        this.userSelected = null
    }

    /**
     * Ferme la modal de sélection d'un utilisateur
     * @returns {void}
     */
    closeSelectUserModal(): void {
        this.showSelectUserModal = false
        this.tabSelected = 'search'
        this.searchString = ''
        this.searchResults = []
        this.userInfo = {
            civility: null,
            name: null,
            first_name: null,
            email: null,
            telephone: '',
            telephone_fixe: ''
        }
    }

    // Ajoute un contact au centre
    /**
     * Recherche un utilisateur
     * @returns {void}
     */
    searchUser(): void {
        if (this.searching) {
            return
        }
        this.searching = true

        this.$store.dispatch('user/getExternUsersFromSearch', { name: this.searchString })
            .then((response) => {
                this.searchResults = response?.data?.data || []
            })
            .finally(() => {
                this.searching = false
            })
    }

    /**
     * Met à jour les données du numéro de téléphone des champs d'ajout d'un utilisateur
     * @returns {void}
     */
    updatePhoneNumberInputDataAddUser(): void {
        this.userInfo.telephone = ''
        if ((this.$refs.phoneInputAddUser as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone = (this.$refs.phoneInputAddUser as any).infosPhoneInput.formattedPhoneNumber
        }

        this.userInfo.telephone_fixe = ''
        if ((this.$refs.phoneFixeInputAddUser as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone_fixe = (this.$refs.phoneFixeInputAddUser as any).infosPhoneInput.formattedPhoneNumber
        }
    }

    /**
     * Met à jour les données du numéro de téléphone des champs d'édition d'un utilisateur
     * @returns {void}
     */
    updatePhoneNumberInputDataEditUser(): void {
        this.userInfo.telephone = ''
        if ((this.$refs.phoneInputEditUser as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone = (this.$refs.phoneInputEditUser as any).infosPhoneInput.formattedPhoneNumber
        }

        this.userInfo.telephone_fixe = ''
        if ((this.$refs.phoneFixeInput as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone_fixe = (this.$refs.phoneFixeInputEditUser as any).infosPhoneInput.formattedPhoneNumber
        }
    }

    /**
     * Ajoute un nouvel utilisateur
     * @returns {void}
     */
    addNewUser(): void {
        if (this.savingUserInfo) {
            return
        }
        this.savingUserInfo = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.updatePhoneNumberInputDataAddUser()
        this.$store.dispatch('user/addUser', this.userInfo)
            .then((response) => {
                this.userSelected = response.data.data
                this.updateValidations()

                this.filtreSortHandler(this.params)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.savingUserInfo = false
            })
    }

    /**
     * Ouvre la section d'édition des informations de l'utilisateur
     * @returns {void}
     */
    openEditUserInfo(): void {
        this.userInfo = {
            civility: this.userSelected.civility,
            name: this.userSelected.name,
            first_name: this.userSelected.first_name,
            email: this.userSelected.email,
            telephone: this.userSelected.telephone,
            telephone_fixe: this.userSelected.telephone_fixe
        }
        this.showEditUserInfo = true
    }

    /**
     * Ferme la section d'édition des informations de l'utilisateur
     * @returns {void}
     */
    closeEditUserInfo(): void {
        this.showEditUserInfo = false
        this.userInfo = {
            civility: null,
            name: null,
            first_name: null,
            email: null,
            telephone: '',
            telephone_fixe: ''
        }
    }

    /**
     * Ouvre le formulaire d'édition des informations de l'utilisateur
     * @returns {void}
     */
    editUserInfo(): void {
        if (this.savingUserInfo) {
            return
        }
        this.savingUserInfo = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.updatePhoneNumberInputDataEditUser()
        this.$store.dispatch('user/updateUser', { user_id: this.userSelected.id, payload: this.userInfo })
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.userSelected = response.data.data
                this.filtreSortHandler(this.params)
                this.closeEditUserInfo()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.savingUserInfo = false
            })
    }

    // -----

    /**
     * @description Ouvre la modale d'importation des utilisateurs
     * @returns {void}
     */
    openImportUsers(): void {
        this.$store.commit('user/SET_ERROR', null)
        this.importFile = null
        this.showImportUsers = true
    }

    /**
     * @description Ferme la modale d'importation des utilisateurs
     * @returns {void}
     */
    closeImportUsers(): void {
        (document.getElementById('input-file') as HTMLInputElement).value = ''
        this.showImportUsers = false
        this.importFile = null
        this.$store.commit('user/SET_ERROR', null)
    }

    /**
     * @description Événement de sélection de fichier
     * @param {any} event - Événement
     * @return {void}
     */
    selectImportFile(event: any): void {
        this.importFile = event.target.files[0]
    }

    /**
     * @description Importation des utilisateurs
     * @returns {void}
     */
    importUsers(): void {
        if (this.importInProgress) {
            return
        }
        this.importInProgress = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Importation en cours ...', infosToaster)

        this.$store.dispatch('user/importReportingUsers', this.importFile)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Importation terminée !', succesToaster)

                await this.$store.dispatch('user/getReportingUsers', this.params)
                this.closeImportUsers()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.importInProgress = false
            })
    }

    /**
     * @description Remplissage du tableau des utilisateurs
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                // Liste des établissements
                const listEtablissements = result.working_establishments
                    ?.map((etablissement: any) =>
                        `<button type="button" class="m-0 p-0 text-info ${this.$route.path === `/bourse_emploi/etablissements/${etablissement.id}` ? 'pe-none' : ''}" onclick="handleTableEvent(['openEtablissement', ${etablissement.id}])">${etablissement.name}</button>`
                    )
                    .join('<br />') || '-'

                const line: any = [
                    { label: '', item: result.name,       type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.first_name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.email,      type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.telephone,  type: 'text', typeAction: null, class: '' }
                ]

                if (!this.isInDetailEstablishment()) {
                    line.push({ label: '', item: listEtablissements, type: 'html', typeAction: null, class: '' })
                }

                if (this.isInDetailEstablishment() && this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE)) {
                    const trashLine = {
                        label: 'Supprimer',
                        item: result,
                        type: 'action',
                        typeAction: 'delete',
                        class: 'text-secondary text-end',
                        icon: 'trash-alt',
                        disabled: false
                    }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Nom',      defautOptionlibelle: 'Rechercher un', model: 'name',       value: '', index: 'name',       datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'name'       } },
            { libelle: 'Prénom',   defautOptionlibelle: 'Rechercher un', model: 'first_name', value: '', index: 'first_name', datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'first_name' } },
            { libelle: 'Courriel', defautOptionlibelle: 'Rechercher un', model: 'email',      value: '', index: 'email',      datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'email'      } }
        ]
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'openEtablissement':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1]}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break
                case 'delete':
                    this.delete(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            if (this.isInDetailEstablishment()) {
                params['filter-working_establishment'] = this.$route.params.working_establishment_id
            }
        }
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            await this.$store.dispatch('user/getReportingUsers', this.params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            if (this.isInDetailEstablishment()) {
                params['filter-working_establishment'] = this.$route.params.working_establishment_id
            }
        }
        await this.$store.dispatch('user/getMoreReportingUsers', this.params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        (window as any).handleTableEvent = this.handleTableEvent
        this.params = {}
        this.params['page'] = 1
        this.params['sort'] = 'name'
        this.params['direction'] = 'asc'
        if (this.isInDetailEstablishment()) {
            this.params['filter-working_establishment'] = this.$route.params.working_establishment_id
        }
        this.buildGenericfields()
        this.setFiltersForGenericTab()
        await this.$store.dispatch('user/getReportingUsers', this.params)
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        (window as any).handleTableEvent = this.handleTableEvent
    }

    /**
     * @description Avant destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        (window as any).handleTableEvent = null
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
