





































































import { mapState }     from 'vuex'
import { Vue, Component, Prop, Watch }          from 'vue-property-decorator'
import { Ability }                              from '@/types/Ability'
import  ErrorDisplay                            from '@/components/ErrorDisplay.vue'
import { formatDate, formatDateDocument, formatDateHoursMinutes, getWeekDayWithDayMonthYear } from '@/utils/helpers'
import { EtatCreneau } from '@/types/Disponibilites'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapState('planification', ['creneaux_dispos', 'creneaux_empty', 'creneaux_dispos_multi']),
        ...mapState('serie', ['series'])
    }
})

export default class PopupGestionSeance extends Vue {
    @Prop() show?: boolean;
    @Prop() candidat_epreuve_select?: boolean;
    @Prop() mode_multi_series?: boolean

    Ability = Ability
    formatDate = formatDate
    formatDateDocument = formatDateDocument
    formatDateHoursMinutes = formatDateHoursMinutes
    getWeekDayWithDayMonthYear = getWeekDayWithDayMonthYear
    EtatCreneau = EtatCreneau
    examinateur = null
    creneau_id_select = 0

    @Watch('show')
    loadExaminateur () {
        if (this.$props.show) {
            this.examinateur = this.$store.getters['examinateur/get_examinateur_temps'](this.$props.candidat_epreuve_select.examinateur_id)
        }
    }

    /**
     * Sélection d'un creneau de remplacement
     */
    selectCreneau (e: any) {
        this.creneau_id_select = Number(e.target.value)
    }

    /**
     * Enregistre le déplacement de la séance
     */
    updatePlanification () {
        this.$store.dispatch('planification/updateSeance', { seance_id: this.$store.state.planification.seance_id_select, creneau_id: this.creneau_id_select, mode: 'UPDATE' }).then(() => {
            this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
            this.$emit('close')
        })
    }

    /**
     * Supprime la planification de la séance
     */
    retirerPlanification () {
        this.$store.dispatch('planification/deleteSeance', { seance_id: this.$store.state.planification.seance_id_select, creneau_id: this.$props.candidat_epreuve_select.creneau.id }).then(() => {
            this.$store.commit('planification/SET_SEANCE_ID_SELECT', null)
            this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
            this.$emit('close')
        })
    }

    /**
     * Ferme la popup avec réinit des variables locales.
     * */
    reset () {
        this.$emit('close')
    }
}

