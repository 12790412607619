















































import { Vue, Component } from 'vue-property-decorator'
import Back from '@/components/Tools/Back.vue'
import Etablissement from '@/views/BourseEmploi/Etablissement.vue'
import ListeUtilisateurs from '@/views/BourseEmploi/ListeUtilisateurs.vue'
import ListePostes from '@/views/BourseEmploi/ListePostes.vue'
import ListeCandidatures from '@/views/BourseEmploi/ListeCandidatures.vue'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        Back,
        Etablissement,
        ListeUtilisateurs,
        ListePostes,
        ListeCandidatures
    },
    computed: {
        forceShowEstablishment(): boolean {
            return !!(this.$store.getters['auth/cannot'](Ability.ADM_ESTABLISHMENT_MANAGE) && this.$store.getters['workingEstablishment/workingEstablishments'].length === 1);
        }
    }
})

export default class GestionEtablissement extends Vue {
    tabSelected = 'etablissement'

    mounted() {
        if (this.$route.query?.tab) {
            document.getElementById(`nav-${this.$route.query.tab}-tab`)?.click()
            this.$router.replace({ query: {} })
        }
    }
}
