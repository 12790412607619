






















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ActivityLog from '@/components/ActivityLog.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupImportCandidat from '@/components/BourseEmploi/PopupImportCandidat.vue'

@Component({
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        'activity-log': ActivityLog,
        ErrorDisplay,
        PopupEditCandidat,
        PopupImportCandidat
    },
    computed: {
        Ability() {
            return Ability
        },
        ...mapGetters('candidatJob', ['candidatJobs', 'loading', 'error', 'meta']),
        ...mapGetters('auth', ['can'])
    }
})

export default class ListeCandidatsBourseEmploi extends Vue {
    filtres: any = []
    filtreJustInitiated = false
    dataForTab: Array<any> = []
    genericfields: Array<any> = []
    readOnly = false
    showCandidatModal = false
    showModalImportCandidat = false
    importFile: any = null
    importInProgress = false

    params: any = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    }

    /**
     * @description Ouvre la modale de consultation des informations du candidat
     * @param {any} candidat - Candidat à consulter
     * @returns {void}
     */
    openCandidatModal(candidat: any): void {
        this.$store.commit('candidatJobDescription/SET_LOADING', true)
        this.$store.dispatch('candidat/getCandidat', { id: candidat.id })
            .then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                this.showCandidatModal = true
            })
            .finally(() => {
                this.$store.commit('candidatJobDescription/SET_LOADING', false)
            })
    }

    /**
     * @description Fermeture de la modale de consultation des informations du candidat
     * @returns {void}
     */
    closeCandidatModal() {
        this.showCandidatModal = false
    }

    /**
     * @description Importe les données
     * @return {void}
     */
    showImportCandidats() {
        this.$store.commit('candidatJob/SET_ERROR', null)
        this.importFile = null
        this.showModalImportCandidat = true
    }

    /**
     * Fermeture de la modale d'importation des candidats
     */
    closeImportCandidats() {
        this.showModalImportCandidat = false
    }

    /**
     * @description Événement de sélection de fichier
     * @param {any} event - Événement
     * @return {void}
     */
    selectImportFile(event: any): void {
        this.importFile = event.target.files[0]
    }

    /**
     * @description Importe les établissements
     * @returns {void}
     */
    importCandidats(): void {
        if (this.importInProgress) {
            return
        }
        this.importInProgress = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Importation en cours ...', infosToaster)

        this.$store.dispatch('candidatJob/importCandidatJobs', this.importFile)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Importation terminée !', succesToaster)

                await this.$store.dispatch('candidatJob/getCandidatJobs', this.params)
                this.closeImportCandidats()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.importInProgress = false
            })
    }

    /**
     * @description Chargement du tableau lors de la modification des données
     * @returns {void}
     */
    @Watch('candidatJobs')
    onCandidatJobsChange(): void {
        this.setDataForGenericTab(this.$store.getters['candidatJob/candidatJobs'])
    }

    /**
     * @description Remplissage du tableau des fiches de poste
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const candidat = result.candidat
                const name = `${candidat.name} ${candidat.first_name}`

                this.dataForTab.push([
                    { label: '', item: candidat, type: 'actionText', typeAction: 'openCandidat', class: 'text-info', text: candidat.code },
                    { label: '', item: candidat, type: 'actionText', typeAction: 'openCandidat', class: 'text-info', text: name },
                    { label: '', item: candidat.email, type: 'text', typeAction: null, class: '' }
                ])
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Code', defautOptionlibelle: 'Rechercher un',  model: 'candidat.code', value: '', index: 'candidat.code', datas: null, loading: this.$store.getters['candidatJob/loading'], options: { type: 'form', fieldsKey: 'candidat.code', strict: true }},
            { libelle: 'Nom', defautOptionlibelle: 'Rechercher un', model: 'candidat.name', value: '', index: 'candidat.name', datas: null, loading: this.$store.getters['candidatJob/loading'], options: { type: 'form', fieldsKey: 'candidat.name' }},
            { libelle: 'Email', defautOptionlibelle: 'Rechercher un', model: 'candidat.email', value: '', index: 'candidat.email', datas: null, loading: this.$store.getters['candidatJob/loading'], options: { type: 'form', fieldsKey: 'candidat.email' }}
        ]
    }

    /**
     * @description Initialisation des colonnes du tableau
     * @returns {void}
     */
    setFieldsForGenericTab(): void {
        this.genericfields = []

        this.genericfields.push(
            { key: 'candidat.code', label: 'Code', sortable: true, class: '', type: 'text' },
            { key: 'candidat.name', label: 'Nom', sortable: true, class: '', type: 'text' },
            { key: 'candidat.email', label: 'Email', sortable: true, class: '', type: 'text' }
        )
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'openCandidat':
                    this.openCandidatModal(paParams[1])
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            this.params = params
            await this.$store.dispatch('candidatJob/getCandidatJobs', params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        this.params = params
        await this.$store.dispatch('candidatJob/getMoreCandidatJobs', params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        this.filtreJustInitiated = true
        await this.$store.dispatch('candidatJob/getCandidatJobs', this.params)
        this.setFieldsForGenericTab()
        this.setFiltersForGenericTab()
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        (window as any).handleTableEvent = this.handleTableEvent
    }
}
