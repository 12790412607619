

















































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import _ from 'lodash'
import { SessionInterface } from '@/types/Session'

@Component({
    computed: {
        ...mapState('user', ['error', 'countError', 'userSelect', 'abilities', 'roles']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('session', ['sessions'])
    },
    methods: {
        ...mapMutations('user', ['SET_USER_SELECT'])
    },
    components: {
        PhoneInput,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})
export default class PopupEditUser extends Vue {
    @Prop() showAdd?: boolean;

    @Watch('userSelect')
    setUser () {
        if (this.$store.state.user.userSelect !== null) {
            this.id = this.$store.state.user.userSelect.id
            this.civility_intervenant = this.$store.state.user.userSelect.civility
            this.first_name = this.$store.state.user.userSelect.first_name
            this.name_intervenant = this.$store.state.user.userSelect.name
            this.email = this.$store.state.user.userSelect.email
            // this.telephone = this.$store.state.user.userSelect.telephone
            this.phoneNumber = this.$store.state.user.userSelect.telephone

            this.user_abilities = this.$store.state.user.userSelect.abilities.filter((a: any) => a.name.includes('own') && a.entity_type === null)
            this.user_sessions_abilities = this.$store.state.user.userSelect.abilities.filter((a: any) => a.name === Ability.RESS_SESSION_VIEW && a.entity_type !== null)
            this.user_abilities = _.orderBy(this.user_abilities, 'title', 'asc')
            this.setRolesName()
        }
    }

    @Watch('showAdd')
    showNoShow () {
        this.showModal = this.$props.showAdd
        this.showSuccess = false
        this.showError = false
    }

    // DATAS
    Ability = Ability
    id = 0
    civility_intervenant = ''
    name_intervenant = ''
    first_name = ''
    email = ''
    telephone = ''
    phoneNumber = ''
    normalizedNumber = ''
    message_success = ''
    roles_list: any = []
    roles_names: string[] = []
    user_abilities: Array<any> = []
    user_sessions_abilities: Array<any> = []
    showSuccess = false
    showError = false
    showModal = false
    envoiEnCours = false
    sessionsForEditUserAbility: Array<any> = []

    // METHODS

    /**
     * checkAbilitySession
     * ajoute ou supprime le droit RESS_SESSION_VIEW sur une session après une action sur la case à coché corespondante
     * @param session: SessionInterface - L'interface de session
     */
    checkAbilitySession(session: SessionInterface) {
        if(this.user_sessions_abilities){
            const indexAb = this.user_sessions_abilities.findIndex((ability_session: any) => ability_session.entity_id === session.id)
            if(indexAb !== -1){
                this.user_sessions_abilities.splice(indexAb, 1)
            } else {
                this.user_sessions_abilities.push({
                    entity_id: session.id,
                    name: Ability.RESS_SESSION_VIEW,
                    entity_type: "App\\Models\\Session",
                    title:session.name,
                    only_owned: false,
                    options: [],
                    scope: null
                })
            }
        }
    }

    /**
     * hasAbilitySessionById
     * Vérifie si un utilisateur à le droit RESS_SESSION_VIEW sur une session par son l'id de la session
     * @param session_id: number - L'identifiant de la session
     */
    hasAbilitySessionById(session_id: number) {
        if(this.user_sessions_abilities) {
            for(let i = 0; i < this.user_sessions_abilities.length; i++) {
                if(this.user_sessions_abilities[i].name  === Ability.RESS_SESSION_VIEW && this.user_sessions_abilities[i].entity_id === session_id) {
                    return true
                }

            }
        }
        return false
    }

    /** Met à jour la valeur locale du numéro de téléphone saisi retourné par le composant PhoneInput */
    updatePhoneNumberInputData () {
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                if ((this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.telephone = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                    this.phoneNumber = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.telephone = ''
                }
            }
        }
    }

    /** Vérifie la validité de la saisie en fonction de l'état des données fournies par le composant PhoneInput */
    checkValidPhoneNumberInputData () {
        let isValid = false
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                isValid = (this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber || (this.$refs.phoneInput as any).infosPhoneInput.phoneNumber === '';
            }
        }
        return isValid
    }

    normalizeNumber () {
        this.normalizedNumber = String(this.telephone).replace(/[^+0-9]/g, '').replace(/^00/, '+').replace(/^0/, '+33')
    }

    updateChamps (e: any) {
        switch (e.target.name) {
            case 'civility':
                this.civility_intervenant = e.target.value
                break
            case 'name':
                this.name_intervenant = e.target.value
                break
            case 'first_name':
                this.first_name = e.target.value
                break
            case 'email':
                this.email = e.target.value
                break
            default:
                break
        }
    }

    // Sauvegarde le user
    submitUser (e: Event) {
        this.envoiEnCours = true
        e.preventDefault()


        const sessions_abilities_temp = []
        for(let i = 0; i <  this.user_sessions_abilities.length; i++) {
            sessions_abilities_temp.push(this.user_sessions_abilities[i].entity_id)
        }

        // this.normalizedNumber = String(this.telephone).replace(/[^+0-9]/g, '').replace(/^00/, '+').replace(/^0/, '+33')
        if (this.checkValidPhoneNumberInputData()) {
            this.updatePhoneNumberInputData()

            const data: any = {
                civility: this.civility_intervenant,
                name: this.name_intervenant,
                first_name: this.first_name,
                email: this.email,
                // telephone: this.normalizedNumber,
                telephone: this.telephone,
                roles: this.roles_names,
                sessions_abilities: sessions_abilities_temp
            }
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
            if (this.id === 0) {
            // Création de l'utilisateur
                this.$store.dispatch('user/addUser', data).then((response) => {
                    this.showError = false
                    this.reinitUser(response)
                    // this.$store.dispatch('user/getUsers')

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                }).catch(() => {
                    this.showError = true
                }).finally(() => {
                    this.$bvToast.hide(idInfo)
                    this.envoiEnCours = false
                })
            } else {
            // Modification de l'utilisateur
                this.$store.dispatch('user/updateUser', { user_id: this.id, payload: data }).then((response) => {
                    this.showError = false
                    this.reinitUser(response)
                    // this.$store.dispatch('user/getUsers')

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                }).catch(() => {
                    this.showError = true
                }).finally(() => {
                    this.$bvToast.hide(idInfo)
                    this.envoiEnCours = false
                })
            }
        } else {
            this.$store.commit('user/SET_ERROR', { telephone: ['Le format du champ Téléphone mobile n\'est pas valide...'] })
            this.showError = true
            this.envoiEnCours = false
        }
    }

    // Reinit les datas du user
    reinitUser (response: any) {
        this.id = 0
        this.civility_intervenant = ''
        this.name_intervenant = ''
        this.first_name = ''
        this.email = ''
        this.normalizedNumber = ''
        this.telephone = ''
        this.roles_names = []
        this.$store.commit('user/SET_USER_SELECT', null)
        this.$store.commit('user/SET_ERROR', null)
        this.showModal = false
        this.showError = false
        this.$store.state.user.error = null

        this.$emit('reinitShow', response ? response.data.data : null)
    }

    // Check / decheck les roles du user
    checkRoles (e: any) {
        const role_name = e.target.value
        const isChecked = e.target.checked

        if (isChecked) {
            this.roles_names.push(role_name)
        } else {
            const index = this.roles_names.findIndex((e: any) => e === role_name)
            this.roles_names.splice(index, 1)
        }
    }

    // Change le flag OTP
    deleteOTP () {
        this.$store.dispatch('user/removeOTP', { user_id: this.id }).then(() => {
            if (this.$store.state.user.error === null) {
                this.showError = false
                this.message_success = 'L\'authentification à deux facteurs est désactivée.'
                this.showSuccess = true
                this.$store.state.user.userSelect.two_factor = false
                // this.$store.dispatch('user/getUsers')
            } else {
                this.showError = true
            }
        })
    }

    // Change le flag reinit password
    reinitPassword () {
        this.$store.dispatch('user/resetPassword', { user_id: this.id }).then(() => {
            if (this.$store.state.user.error === null) {
                this.showError = false
                this.message_success = 'La demande de réinitialisation de mot de passe est prise en compte.'
                this.showSuccess = true
                this.$store.state.user.userSelect.password_changed = false
                // this.$store.dispatch('user/getUsers')
            } else {
                this.showError = true
            }
        })
    }

    setRolesName () {
        for (const a in this.$store.state.user.userSelect.roles) {
            this.roles_names.push(this.$store.state.user.userSelect.roles[a].name)
        }
    }

    // Filtre la liste des rôles pour enlever les abilities en gci
    create_list_roles () {
        for (const r in this.$store.state.user.roles) {
            if (!this.$store.state.user.roles[r].abilities.find((a: any) => a.name.indexOf('gci') !== -1)) {
                this.roles_list.push(this.$store.state.user.roles[r])
            }
        }
    }

    // Retourne le nom de la session associée à l'ability
    get_session_name (scope_id: number) {
        const session_sel = this.$store.state.session.sessions.find((s: any) => s.id === scope_id)
        if (session_sel) {
            return session_sel.name + '- '
        } else {
            return ''
        }
    }

    created () {
        this.$store.dispatch('session/getSessionsForEditUserAbility').then((response) => {
            this.sessionsForEditUserAbility = response.data.data
            if (this.$store.state.user.roles.length === 0) {
                this.$store.dispatch('user/getRoles').then(() => {
                    this.create_list_roles()
                })
            } else {
                this.create_list_roles()
            }
            if (this.$store.state.user.userSelect) {
                this.id = this.$store.state.user.userSelect.id
                this.civility_intervenant = this.$store.state.user.userSelect.civility
                this.first_name = this.$store.state.user.userSelect.first_name
                this.name_intervenant = this.$store.state.user.userSelect.name
                this.email = this.$store.state.user.userSelect.email
                this.telephone = this.$store.state.user.userSelect.telephone
                this.user_abilities = this.$store.state.user.userSelect.abilities.filter((a: any) => a.name.includes('own'))
                this.setRolesName()
            }
        })
    }
}
