


















































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import Back from '@/components/Tools/Back.vue'
import _ from 'lodash'
import { ConcourInterface } from '@/types/Concour'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'

@Component({
    computed: {
        Ability() {
            return Ability
        },
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('concour', ['concours'])
    },
    components: {
        ErrorDisplay,
        ExaGenericTable,
        FontAwesomeIcon,
        PhoneInput,
        Back
    }
})

export default class AccesResutats extends Vue {
    filtres: any = []
    dataForTab: Array<any> = []
    showSelectUserModal = false
    showEditPermissionModal = false
    showDeletePermissionModal = false
    showSendMailModal = false
    showEditUserInfo = false
    tabSelected = 'search'
    mailTo = ''
    mailsOverflow = false
    timeoutBtnSendMail: any = null
    userSelected: any = null
    searching = false
    searchString = ''
    searchResults: Array<any> = []
    loading = true
    concoursList: Array<ConcourInterface> = []
    selectedAbility: any = null
    selectedConcour: any = null
    permissionsList: Array<any> = []
    params: any = {}
    genericfields: any = []
    savingUserInfo = false
    userInfo: any = {
        civility: null,
        name: null,
        first_name: null,
        email: null,
        telephone: null,
        telephone_fixe: null
    }

    abilities: Array<any> = [
        { value: Ability.EXT_RESULTS_VIEW, name: 'Consulter les résultats' },
        { value: Ability.EXT_BARRE_MANAGE, name: 'Définir la barre' }
    ]

    @Watch('userSelected', { deep: true })
    onUserSelectedChange(data: any): void {
        this.permissionsList = []
        if (data) {
            this.abilities.forEach((ability: any) => {
                const concours = [ ...new Set(data.abilities
                    .filter((_ability: any) => _ability.name === ability.value && _ability.entity_id !== null)
                    .reduce((acc: any, _ability: any) => {
                        const concour = this.concoursList.find((concour: any) => concour.id === _ability.entity_id)
                        if (concour) {
                            acc.push(concour)
                        }
                        return acc
                    }, [])) ]

                concours.forEach((concour: any) => {
                    this.permissionsList.push({
                        ability: ability,
                        concour: concour
                    })
                })
            })
            this.permissionsList = _.orderBy(this.permissionsList, ['ability.name', 'concour.name'], ['asc', 'asc'])
        }
    }

    /**
     * Ouvre la modal de sélection d'un utilisateur
     * @returns {void}
     */
    openSelectUserModal(): void {
        this.showSelectUserModal = true
    }

    /**
     * Ferme la modal de sélection d'un utilisateur
     * @returns {void}
     */
    closeSelectUserModal(): void {
        this.showSelectUserModal = false
        this.tabSelected = 'search'
        this.searchString = ''
        this.searchResults = []
        this.userInfo = {
            civility: null,
            name: null,
            first_name: null,
            email: null,
            telephone: null,
            telephone_fixe: null
        }
    }

    /**
     * Ouvre la modal de modification des permissions
     * @param {any} data - Données de l'utilisateur
     * @returns {void}
     */
    openEditPermissionModal(data: any): void {
        if (this.showSelectUserModal) {
            this.closeSelectUserModal()
        }
        this.userSelected = data
        this.showEditPermissionModal = true
    }

    /**
     * Ferme la modal de modification des permissions
     * @returns {void}
     */
    closeEditPermissionModal(): void {
        this.showEditPermissionModal = false
        this.userSelected = null
        this.selectedAbility = null
        this.selectedConcour = null
    }

    /**
     * Ouvre la modal de suppression des permissions
     * @param {any} data - Données de l'utilisateur
     * @returns {void}
     */
    openDeletePermissionModal(data: any): void {
        this.userSelected = data
        this.showDeletePermissionModal = true
    }

    /**
     * Ferme la modal de suppression des permissions
     * @returns {void}
     */
    closeDeletePermissionModal(): void {
        this.showDeletePermissionModal = false
        this.userSelected = null
    }

    /**
     * Ouvre la modal d'envoie d'emails
     * @returns {void}
     */
    openSendMailModal(): void {
        this.mailTo = this.dataForTab.map((user: any) => user[0].item.email).join(';')
        this.mailsOverflow = this.mailTo.length > 1800

        this.$nextTick()
            .then(() => this.showSendMailModal = true)
    }

    /**
     * Ferme la modal d'envoie d'emails
     * @returns {void}
     */
    closeSendMailModal(): void {
        this.showSendMailModal = false
        this.mailTo = ''
        this.mailsOverflow = false

        if (this.timeoutBtnSendMail) {
            clearTimeout(this.timeoutBtnSendMail)
            this.timeoutBtnSendMail = null
        }
    }

    /**
     * Envoie un / des emails
     * @returns {void}
     */
    sendMail(): void {
        if (this.mailsOverflow) {
            navigator.clipboard.writeText(this.mailTo)

            const button: any = document.getElementById('send-mail')
            if (button) {
                button.innerHTML = 'Copié !'
                this.timeoutBtnSendMail = setTimeout(() => {
                    button.innerHTML = 'Copier'
                }, 3000)
            }
        } else {
            window.open(`mailto:${this.mailTo}`, '_self')
        }
    }

    /**
     * Récupération des données en fonction des paramètres
     * @param {any} params - Paramètres à appliquer
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        this.loading = true
        this.params = params
        this.$store.dispatch('user/getExternUsers', this.params)
            .then((response) => this.setDataForGenericTab(response.data.data))
            .finally(() => this.loading = false)
    }

    /**
    * loadHandler
    * Cette fonction gère le chargement des données en fonction des paramètres fournis.
    * @param params: paramètres de chargement des données
    */
    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('epreuve/getMoreExternUsers', this.params)
                .then((response) => this.setDataForGenericTab(response.data.data))
                .finally(() => this.loading = false)
        }
    }

    /**
     * Gestion des événements du tableau
     * @param {any} paParams - Paramètres
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'edit':
                    this.openEditPermissionModal(paParams[1])
                    break
                case 'remove':
                    this.openDeletePermissionModal(paParams[1])
                    break
            }
        }
    }

    /**
     * Définit les colonnes du tableau
     * @returns {void}
     */
    setFieldsForGenericTab(): void {
        this.genericfields = [
            { key: 'edit',       label: '',            sortable: false, class: '', type: 'action' },
            { key: 'user.name',  label: 'Utilisateur', sortable: true,  class: '', type: 'text'   },
            { key: 'user.email', label: '',            sortable: false, class: '', type: 'text'   }
        ]

        this.abilities.forEach((ability: any) => {
            this.genericfields.push({ key: ability.value, label: `Permission "${ability.name}"`, sortable: false, class: '', type: 'text' })
        })

        this.genericfields.push({ key: 'remove', label: '', sortable: false, class: '', type: 'action' })
    }

    /**
     * Définit les filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            {
                libelle: 'Utilisateur',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                index: 'name',
                datas: null,
                loading: this.loading,
                options: { type: 'form', fieldsKey: 'user.name' }
            }
        ]
    }

    /**
     * Définit les données du tableau
     * @param {any} poData - Données à afficher
     * @returns {void}
     */
    setDataForGenericTab(poData: any): void {
        this.dataForTab = []
        if (poData) {
            for (const result of poData) {
                const icone = { label: 'Éditer', icon: 'pen' }
                const fullName = `${result.first_name} ${result.name}`
                const email = `<a href="mailto:${result.email}">${result.email}</a>`

                const line = [
                    { label: icone.label, item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: fullName, type: 'text', typeAction: null, class: '' },
                    { label: '', item: email, type: 'html', typeAction: null, class: '' }
                ]

                this.abilities.forEach((ability: any) => {
                    const concours = [
                        // Liste d'ID des concours de l'utilisateur associé au tableau des permissions possibles
                        ...new Set(
                            result?.abilities?.filter((_ability: any) => _ability.name === ability.value && _ability.entity_id !== null)
                                .map((_ability: any) => _ability.entity_id)
                                .filter((_ability: any) => _ability !== null)
                        )
                    ]
                        // Récupération des noms des concours
                        .map((concour_id: any) => {
                            return this.concoursList
                                .filter((concour: any) => concour.id === concour_id)
                                .map((concour: any) => concour.name)
                        })
                        .flat(1)
                        .sort()
                        .join('<br>')

                    const item = concours ? `<p class="m-0">${concours}</p>` : '-'
                    line.push({ label: '', item: item, type: 'html', typeAction: null, class: '' })
                })

                line.push({ label: 'Supprimer', item: result, type: 'action', typeAction: 'remove', class: 'commons_last_action_button text-danger text-end', icon: 'trash-alt', disabled: false })
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Ajoute une permission à la liste
     * @returns {void}
     */
    addPermission(): void {
        if (this.selectedAbility === null || this.selectedConcour === null) {
            return
        }

        if (this.selectedAbility.value === Ability.EXT_BARRE_MANAGE) {
            if (!this.permissionsList.find((permission: any) => permission.ability.value === Ability.EXT_RESULTS_VIEW && permission.concour.id === this.selectedConcour.id)) {
                this.permissionsList.push({
                    ability: this.abilities.find((ability: any) => ability.value === Ability.EXT_RESULTS_VIEW),
                    concour: this.selectedConcour
                })
            }
        }

        this.permissionsList.push({
            ability: this.selectedAbility,
            concour: this.selectedConcour
        })

        this.permissionsList = _.orderBy(this.permissionsList, ['ability.name', 'concour.name'], ['asc', 'asc'])

        this.selectedConcour = null
    }

    /**
     * Retire une permission de la liste
     * @param {number} index - Position de la permission dans la liste
     * @returns {void}
     */
    removePermission(index: number): void {
        if (this.permissionsList[index].ability.value === Ability.EXT_RESULTS_VIEW) {
            const indexToRemove = this.permissionsList.findIndex((permission: any) => permission.ability.value === Ability.EXT_BARRE_MANAGE && permission.concour.id === this.permissionsList[index].concour.id)
            if (indexToRemove !== -1) {
                this.permissionsList.splice(indexToRemove, 1)
            }
        }

        this.permissionsList.splice(index, 1)
    }

    /**
     * Modifie les permissions de l'utilisateur sélectionné
     * @returns {void}
     */
    updatePermissions(): void {
        if (!this.userSelected || this.loading) {
            return
        }
        this.loading = true

        const payload: any = { abilities: {}}

        if (!this.showDeletePermissionModal) {
            const uniqueAbilities = [
                ...new Set(this.permissionsList
                    .map((permission: any) => permission.ability.value))
            ]
            uniqueAbilities.forEach((ability) => {
                payload.abilities[ability] = this.permissionsList
                    .filter((permission: any) => permission.ability.value === ability)
                    .map((permission: any) => permission.concour.id)
            })
        }

        this.$store.dispatch('user/editExternUser', { user_id: this.userSelected.id, data: payload})
            .then(() => {
                this.closeDeletePermissionModal()
                this.closeEditPermissionModal()
                this.filtreSortHandler(this.params)
            })
            .catch(() => {
                this.loading = false
            })
    }

    /**
     * Recherche un utilisateur
     * @returns {void}
     */
    searchUser(): void {
        if (this.searching) {
            return
        }
        this.searching = true

        this.$store.dispatch('user/getExternUsersFromSearch', { name: this.searchString })
            .then((response) => {
                this.searchResults = response?.data?.data || []
            })
            .finally(() => {
                this.searching = false
            })
    }

    /**
     * Met à jour les données du numéro de téléphone des champs d'ajout d'un utilisateur
     * @returns {void}
     */
    updatePhoneNumberInputDataAddUser(): void {
        this.userInfo.telephone = ''
        if ((this.$refs.phoneInputAddUser as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone = (this.$refs.phoneInputAddUser as any).infosPhoneInput.formattedPhoneNumber
        }

        this.userInfo.telephone_fixe = ''
        if ((this.$refs.phoneFixeInputAddUser as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone_fixe = (this.$refs.phoneFixeInputAddUser as any).infosPhoneInput.formattedPhoneNumber
        }
    }

    /**
     * Met à jour les données du numéro de téléphone des champs d'édition d'un utilisateur
     * @returns {void}
     */
    updatePhoneNumberInputDataEditUser(): void {
        this.userInfo.telephone = ''
        if ((this.$refs.phoneInputEditUser as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone = (this.$refs.phoneInputEditUser as any).infosPhoneInput.formattedPhoneNumber
        }

        this.userInfo.telephone_fixe = ''
        if ((this.$refs.phoneFixeInput as any)?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.userInfo.telephone_fixe = (this.$refs.phoneFixeInputEditUser as any).infosPhoneInput.formattedPhoneNumber
        }
    }

    /**
     * Ajoute un nouvel utilisateur
     * @returns {void}
     */
    addNewUser(): void {
        if (this.savingUserInfo) {
            return
        }
        this.savingUserInfo = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.updatePhoneNumberInputDataAddUser()
        this.$store.dispatch('user/addUser', this.userInfo)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.filtreSortHandler(this.params)
                this.openEditPermissionModal(response.data.data)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.savingUserInfo = false
            })
    }

    /**
     * Ouvre la section d'édition des informations de l'utilisateur
     * @returns {void}
     */
    openEditUserInfo(): void {
        this.userInfo = {
            civility: this.userSelected.civility,
            name: this.userSelected.name,
            first_name: this.userSelected.first_name,
            email: this.userSelected.email,
            telephone: this.userSelected.telephone,
            telephone_fixe: this.userSelected.telephone_fixe
        }
        this.showEditUserInfo = true
    }

    /**
     * Ferme la section d'édition des informations de l'utilisateur
     * @returns {void}
     */
    closeEditUserInfo(): void {
        this.showEditUserInfo = false
        this.userInfo = {
            civility: null,
            name: null,
            first_name: null,
            email: null,
            telephone: null,
            telephone_fixe: null
        }
    }

    /**
     * Ouvre le formulaire d'édition des informations de l'utilisateur
     * @returns {void}
     */
    editUserInfo(): void {
        if (this.savingUserInfo) {
            return
        }
        this.savingUserInfo = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.updatePhoneNumberInputDataEditUser()
        this.$store.dispatch('user/updateUser', { user_id: this.userSelected.id, payload: this.userInfo })
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.userSelected = response.data.data
                this.filtreSortHandler(this.params)
                this.closeEditUserInfo()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.savingUserInfo = false
            })
    }

    /**
     * Montage du composant
     * @returns {void}
     */
    mounted(): void {
        this.setFieldsForGenericTab()
        this.$store.dispatch('concour/getConcoursActifs', { 'filter-is_banque': 0 })
            .then(() => {
                this.concoursList = _.orderBy(
                    this.$store.state.concour.concours,
                    'name',
                    'asc'
                )
                this.setFiltersForGenericTab()
                this.$store.dispatch('user/getExternUsers', this.params)
                    .then((response) => this.setDataForGenericTab(response.data.data))
                    .finally(() => this.loading = false)
            })
    }
}
