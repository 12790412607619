




































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PopupEditUser from '@/components/Administration/Users/PopupEditUser.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { checkIcone, convertDateLocalValeurT, formatDate, formatDateVariante, dateisSameOrBefore } from '@/utils/helpers'
import { JobDescriptionStatus } from '@/types/JobDescription'
import ActivityLog from '@/components/ActivityLog.vue'

import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        ExaGenericTable,
        PopupEditUser,
        'font-awesome-icon': FontAwesomeIcon,
        'activity-log': ActivityLog,
        ErrorDisplay
    },
    computed: {
        canEdit(): boolean {
            const can = this.$store.getters['auth/can']
            return can(Ability.INTERV_OWN_ESTABLISHMENT) || can(Ability.ADM_ESTABLISHMENT_MANAGE)
        },
        ...mapGetters('workingEstablishment', ['workingEstablishments', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('session', ['sessionSelect'])
    }
})

export default class ListeEtablissements extends Vue {
    Ability                        = Ability
    filtres: any                   = []
    filtreJustInitiated            = false
    dataForTab: Array<any>         = []
    params: any                    = null
    convertDateLocalValeurT        = convertDateLocalValeurT
    showModalParametrages          = false
    showImportEstablishments       = false
    job_descriptions_start_at: any = null
    job_descriptions_end_at: any   = null
    importFile: any                = null
    importInProgress               = false
    formatDate                     = formatDate
    formatDateVariante             = formatDateVariante

    // Ensemble des colonnes du tableau
    genericfields: any = []

    // ------historique

    togglePopupHistoriqueWorkingEstablishment = false
    errorKeeper = null
    last_save: any = null
    historiqueWorkingEstablishment: any = null

    /**
     * Toggle le popup d'historique des validations / invalidations des barres
     * @returns {void}
     */
    openPopupHistoriqueWorkingEstablishment(working_establishment_id: any): void {
        this.errorKeeper = null
        this.historiqueWorkingEstablishment = null
        this.$store.dispatch('workingEstablishment/getActivityLog', {working_establishment_id: working_establishment_id}).then((response) => {
            this.historiqueWorkingEstablishment = response.data.data
            this.togglePopupHistoriqueWorkingEstablishment = true
        })

    }

    closePopupHistoriqueWorkingEstablishment() {
        this.historiqueWorkingEstablishment = null
        this.togglePopupHistoriqueWorkingEstablishment = false
    }

    /**
     * @description Mise à jour du tableau des utilisateurs
     * @returns {void}
     */
    @Watch('workingEstablishments')
    onWorkingEstablishmentsChange(): void {
        this.setDataForGenericTab(this.$store.getters['workingEstablishment/workingEstablishments'])
    }

    buildGenericfields () {
        this.genericfields = [
            { key: 'edit',        label: '',                      sortable: false,                      class: '',    type: 'action' },
            { key: 'name',        label: 'Nom',                   sortable: true,                       class: '',    type: 'text'   },
            { key: 'adress',      label: 'Adresse',               sortable: true,                       class: '',    type: 'text'   },
            { key: 'code_postal', label: 'Code postal',           sortable: true,                       class: '',    type: 'text'   },
            { key: 'ville',       label: 'Ville',                 sortable: true,                       class: '',    type: 'text'   },
            { key: 'updated_at',  label: 'Dernière modification', sortable: true, sortDirection: 'asc', class: 'ps-5'                }
        ]
        if(this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE)) {
            this.genericfields.push({ key: 'delete', label: '', sortable: false, class: '', type: 'action' })
        }
    }

    /**
     * @description Remplissage du tableau des établissements
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE)
                const icone = checkIcone(Ability.ADM_ESTABLISHMENT_MANAGE, can)

                let deleteIsDisabled = false
                if(result.jobDescriptions && result.jobDescriptions.length > 0) {
                    for (let i = 0; i < result.jobDescriptions.length; i++) {
                        if(result.jobDescriptions[i].status !== JobDescriptionStatus.STATUS_DRAFT) {
                            deleteIsDisabled = true
                        }
                    }
                }
                const line: any = [
                    { label: 'Éditer', item: result,             type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: 'pen', disabled: false },
                    { label: '',       item: result.name,        type: 'text',   typeAction: null,   class: ''                                                          },
                    { label: '',       item: result.adress,      type: 'text',   typeAction: null,   class: ''                                                          },
                    { label: '',       item: result.code_postal, type: 'text',   typeAction: null,   class: ''                                                          },
                    { label: '',       item: result.ville,       type: 'text',   typeAction: null,   class: ''                                                          }
                ]

                if((this.last_save === null && result.updated_at) || (this.last_save && result.updated_at && dateisSameOrBefore(this.last_save, result.updated_at))) {
                    this.last_save = result.updated_at
                }
                const updated_at = (result.updated_at ? formatDateVariante(result.updated_at) : ' - ')
                const updated_at_icon = [{
                    name: 'clock',
                    class: 'text-info text-small text-start',
                    title: updated_at,
                    value_comp: updated_at,
                    typeAction: 'popupHistorique',
                    item: result
                }]
                line.push({ label: '', item: updated_at_icon, type: 'icons', typeAction: 'popupHistorique', class: 'text-center' })

                if (this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE)) {
                    const trashLine = {
                        label: (deleteIsDisabled ? 'Vous ne pouvez pas supprimer un établissement qui possède des fiches de poste avec un statut différent de brouillon.' : 'Supprimer'),
                        item: result,
                        type: 'action',
                        typeAction: 'delete',
                        class: 'text-secondary text-end',
                        icon: 'trash-alt',
                        disabled: false
                    }
                    line.push(trashLine)
                }

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Nom',         defautOptionlibelle: 'Rechercher un',  model: 'name',        value: '', index: 'name',        datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'name'        } },
            { libelle: 'adress',      defautOptionlibelle: 'Rechercher une', model: 'adress',      value: '', index: 'adress',      datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'adress'      } },
            { libelle: 'Code postal', defautOptionlibelle: 'Rechercher un',  model: 'code_postal', value: '', index: 'code_postal', datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'code_postal' } },
            { libelle: 'Ville',       defautOptionlibelle: 'Rechercher une', model: 'ville',       value: '', index: 'ville',       datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'ville'       } }
        ]
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1].id}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break
                case 'delete':
                    await this.deleteEstablishment(paParams[1])
                    break
                case 'popupHistorique':
                    this.openPopupHistoriqueWorkingEstablishment(paParams[1][0].item.id)
                    break
            }
        }
    }

    // ----- ajout edition suppression etablissement

    showModalEditionEstablishment = false
    showModalMessageDelete = false
    establishmentTemp: any = null
    establishment: any  = null

    userToRemove: any = null

    openAddEstablishment () {
        this.establishmentTemp = {
            id: 0,
            session_id: this.$store.getters['auth/user_session_id'],
            name: '',
            adress: '',
            ville: '',
            code_postal: '',
            num_affiliation: ''
        }
        this.showModalEditionEstablishment = true
    }

    editEstablishment (data: any) {
        this.establishment = data
        this.establishmentTemp = {
            id: this.establishment.id,
            session_id: this.establishment.session_id,
            name: this.establishment.name,
            adress: this.establishment.adress,
            ville: this.establishment.ville,
            code_postal: this.establishment.code_postal,
            num_affiliation: this.establishment.num_affiliation
        }
        this.showModalEditionEstablishment = true
    }

    cancelEdit () {
        this.establishment = null
        this.establishmentTemp = null
        this.showModalEditionEstablishment = false
    }

    editSuite () {
        const payload = {
            id: this.establishmentTemp.id,
            session_id: this.establishmentTemp.session_id,
            name: this.establishmentTemp.name,
            adress: this.establishmentTemp.adress,
            ville: this.establishmentTemp.ville,
            code_postal: this.establishmentTemp.code_postal,
            num_affiliation: this.establishmentTemp.num_affiliation
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.establishmentTemp.id === 0) {
            this.$store.dispatch('workingEstablishment/addWorkingEstablishment', payload)
                .then(() => {
                    this.establishment = null
                    this.establishmentTemp = null
                    this.showModalEditionEstablishment = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Établissment ajouté avec succès !', succesToaster)
                    this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params).then(() => {
                        this.setDataForGenericTab(this.$store.state.establishment.establishments)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('workingEstablishment/updateWorkingEstablishment', payload)
                .then(() => {
                    this.establishment = null
                    this.establishmentTemp = null
                    this.showModalEditionEstablishment = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Établissement modifié avec succès !', succesToaster)
                    this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params).then(() => {
                        this.setDataForGenericTab(this.$store.state.establishment.establishments)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deleteIsDisabled = false
    deleteEstablishment (data: any) {
        this.establishment = data
        this.showModalMessageDelete = true
        this.deleteIsDisabled = false
        if(data.jobDescriptions && data.jobDescriptions.length > 0) {
            for (let i = 0; i < data.jobDescriptions.length; i++) {
                if(data.jobDescriptions[i].status !== JobDescriptionStatus.STATUS_DRAFT) {
                    this.deleteIsDisabled = true
                }
            }
        }
    }

    cancelDelete () {
        this.establishment = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('workingEstablishment/deleteWorkingEstablishment', this.establishment.id)
            .then(() => {
                this.establishment = null
                this.establishmentTemp = null
                this.showModalEditionEstablishment = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Establishment supprimé avec succès !', succesToaster)
                this.establishment = null
                this.showModalMessageDelete = false
                this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params).then(() => {
                    this.setDataForGenericTab(this.$store.state.establishment.establishments)
                }).catch((error) => {
                    console.log('ko:' + error)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    // ----

    /**
     * @description Ouvre la modale d'import des établissements
     * @returns {void}
     */
    openImportEstablishments(): void {
        this.$store.commit('workingEstablishment/SET_ERROR', null)
        this.importFile = null
        this.showImportEstablishments = true
    }

    /**
     * @description Ferme la modale d'import des établissements
     * @returns {void}
     */
    closeImportEstablishments(): void {
        (document.getElementById('input-file') as HTMLInputElement).value = ''
        this.showImportEstablishments = false
        this.importFile = null
        this.$store.commit('workingEstablishment/SET_ERROR', null)
    }

    /**
     * @description Événement de sélection de fichier
     * @param {any} event - Événement
     * @return {void}
     */
    selectImportFile(event: any): void {
        this.importFile = event.target.files[0]
    }

    /**
     * @description Importe les établissements
     * @returns {void}
     */
    importEstablishments(): void {
        if (this.importInProgress) {
            return
        }
        this.importInProgress = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Importation en cours ...', infosToaster)

        this.$store.dispatch('workingEstablishment/importWorkingEstablishment', this.importFile)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Importation terminée !', succesToaster)

                await this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params)
                this.closeImportEstablishments()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.importInProgress = false
            })
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            await this.$store.dispatch('workingEstablishment/getWorkingEstablishments', params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        await this.$store.dispatch('workingEstablishment/getMoreWorkingEstablishments', params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        await this.$store.dispatch('workingEstablishment/getWorkingEstablishments', { page: 1, sort: 'name', direction: 'asc' })
        const workingEstablishments = this.$store.getters['workingEstablishment/workingEstablishments']
        if (this.$store.getters['auth/cannot'](Ability.ADM_ESTABLISHMENT_MANAGE) && workingEstablishments.length === 1) {
            await this.$router.push(`/bourse_emploi/etablissements/${workingEstablishments[0].id}`)
        }
        this.buildGenericfields()
        this.setFiltersForGenericTab()
    }
}
