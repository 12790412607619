





































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
import { WorkingEstablishmentInterface } from '@/types/WorkingEstablishment'
import { formatDateVariante } from '@/utils/helpers'
import ActivityLog from '@/components/ActivityLog.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        'activity-log': ActivityLog,
        ErrorDisplay
    },
    computed: {
        canEdit() {
            const can = this.$store.getters['auth/can']
            return can(Ability.INTERV_OWN_ESTABLISHMENT) || can(Ability.ADM_ESTABLISHMENT_MANAGE)
        },
        ...mapGetters('workingEstablishment', ['error']),
        ...mapGetters('auth', ['can'])
    }
})

export default class DetailsEtablissement extends Vue {
    establishment: any = {} as WorkingEstablishmentInterface
    editMode = false
    processing = false

    /**
     * @description Réinitialise les données de l'établissement en cas d'annulation de la modification
     * @param {boolean} value - Valeur de l'état d'édition
     * @returns {void}
     */
    @Watch('editMode')
    onEditModeChange(value: boolean): void {
        if (!value && !this.processing) {
            this.establishment = JSON.parse(JSON.stringify(this.$store.getters['workingEstablishment/workingEstablishmentSelect']))
        }
    }

    // ------historique
    formatDateVariante = formatDateVariante

    togglePopupHistoriqueWorkingEstablishment = false
    errorKeeper = null
    lastSave: any = null
    historiqueWorkingEstablishment: any = null

    /**
     * Toggle le popup d'historique des validations / invalidations des barres
     * @returns {void}
     */
    openPopupHistoriqueWorkingEstablishment(working_establishment_id: any): void {
        this.errorKeeper = null
        this.historiqueWorkingEstablishment = null
        this.$store.dispatch('workingEstablishment/getActivityLog', {working_establishment_id: working_establishment_id}).then((response) => {
            this.historiqueWorkingEstablishment = response.data.data
            this.togglePopupHistoriqueWorkingEstablishment = true
        })

    }

    closePopupHistoriqueWorkingEstablishment() {
        this.historiqueWorkingEstablishment = null
        this.togglePopupHistoriqueWorkingEstablishment = false
    }


    /**
     * @description Enregistre les modifications apportées à l'établissement
     * @returns {void}
     */
    editEstablishment(): void {
        if (this.processing) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload: WorkingEstablishmentInterface = {
            id: this.establishment.id,
            session_id: this.establishment.session_id,
            name: this.establishment.name,
            adress: this.establishment.adress,
            ville: this.establishment.ville,
            code_postal: this.establishment.code_postal,
            num_affiliation: this.establishment.num_affiliation
        }

        this.$store.dispatch('workingEstablishment/updateWorkingEstablishment', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Établissement modifié avec succès !', succesToaster)

                this.editMode = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        await this.$store.dispatch('workingEstablishment/getWorkingEstablishment', {
            working_establishment_id: parseInt(this.$route.params.working_establishment_id)
        })
        this.establishment = JSON.parse(JSON.stringify(this.$store.getters['workingEstablishment/workingEstablishmentSelect']))
    }
}
