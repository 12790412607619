













































































































































































































































import { Vue, Component, Prop }    from 'vue-property-decorator'
import { mapState }             from 'vuex'
import { FontAwesomeIcon }                  from '@fortawesome/vue-fontawesome'
import { formatDateHoursMinutes, getWeekDayWithDayMonth }           from '@/utils/helpers'
import PopupGestionSeance                   from '@/components/Planification/PopupGestionSeance.vue'
import { EntityType, getEntityTypeShortFormat, PosteType } from '@/types/Poste'
import { TypePassation } from '@/types/Epreuve'
import { EtatCreneau } from '@/types/Disponibilites'
import { EtatPlanificationAuto } from '@/types/Planification'
import { TypeEnsemble } from '@/types/Ensemble'
import CandidatMultiSeries from '@/views/Planification/Vues/CandidatMultiSeries.vue'
import { etat_seance } from '@/types/Seance'


@Component({
    computed: {
        ...mapState('planification', ['planification_en_cours', 'error_planification', 'serie_selected', 'banque_selected', 'ensemble_selected', 'jours_series', 'liste_candidats_planification', 'loading_planification', 'last_page', 'creneaux_dispos', 'creneaux_empty', 'candidat_id_select', 'seance_id_select', 'jours_multi_series', 'count_dispos_P1', 'count_dispos_P2'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        PopupGestionSeance,
        CandidatMultiSeries
    }
})

export default class Candidats extends Vue
{
    @Prop() chaine_full_concours?: string
    @Prop() load_datas?: boolean
    @Prop() load_interface?: boolean
    @Prop() read_only?: boolean
    @Prop() full_read_only?: boolean // Permet un affichage du calendrier en mode full read only sans aucune action possible
    @Prop() hide_header_infoCand_display?: boolean // Précise si on veut masquer le header affichant les infos de base du candidat (code, nom prenom et équipes).
    @Prop() header_serie_display?: boolean // Ajoute un header affichant la série du candidat.


    getWeekDayWithDayMonth = getWeekDayWithDayMonth
    formatDateHoursMinutes = formatDateHoursMinutes
    EtatCreneau = EtatCreneau
    EtatPlanificationAuto = EtatPlanificationAuto
    etat_seance = etat_seance
    showPopupGestionSeance = false
    candidat_epreuve_select: any = null
    page            = 1
    windowTop       = 0
    maxScroll       = 0
    showPopupInfo = false
    edition_seance = false
    showPopupMultiSerieCandidat = false
    candidat_multi_serie: any = null
    show_chevauchement_multi_serie = false
    seance_select: any = null



    /**
     * Détection du bas de la liste pour charger les autres candidats
     */
    handleScroll (event: any) {
        if (this.$store.state.planification.last_page === undefined || this.$store.state.planification.last_page === 0)
        {
            return
        }
        this.windowTop = Number((event.srcElement.scrollTop).toFixed(0))
        this.maxScroll = event.srcElement.scrollHeight - 50

        // Scroll vers le bas
        if (!this.$props.load_datas && !this.$store.state.planification.loading_planification && (this.windowTop > (this.maxScroll - (event.srcElement.offsetHeight))) && (this.page !== this.$store.state.planification.last_page)) {
            this.page += 1
            this.$emit('onLoadPage', this.page)
        }
    }

    /**
     * Met en évidence sur le calendrier les disponibilités de l'examinateur pour l'épreuve choisie
     */
    getDisposExaminateur (seance_id: number, candidat_id: number, seance: any) {
        this.$store.state.planification.error_planification = null
        if (this.seance_select !== null && seance_id === this.seance_select.id) {
            // Reset des créneaux dispos la sélection est désélectionnée
            this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
            this.$store.commit('planification/SET_SEANCE_ID_SELECT', null)
            this.seance_select = null
        } else {
            if (this.$store.state.planification.planification_en_cours === EtatPlanificationAuto.EN_COURS) {
                if (!this.$props.read_only) {
                    this.seance_select = seance
                    this.$store.state.planification.creneaux_empty = false
                    this.$store.commit('planification/SET_CANDIDAT_ID_SELECT', candidat_id)
                    this.$store.commit('planification/SET_SEANCE_ID_SELECT', seance_id)
                    this.$store.dispatch('planification/getCreneauxDispos', { seance_id: seance_id })
                } else {
                    this.showPopupInfo = true
                }
            }
        }
    }

    /**
     * Ouvre le popup d'édition d'une épreuve non affectée
     */
    editSeance (candidat: any, seance: any, jour: string) {
        this.$store.state.planification.error_planification = null
        if (!this.$props.read_only) {
            this.edition_seance = true
            // Prépare les datas pour l'affichage dans le popup
            const candidat_ensemble = {
                candidat : candidat,
                epreuve: seance.epreuve_name,
                ensemble : this.$store.getters['ensemble/ensembleById'](seance.ensemble_id),
                filiere_ensemble: this.getConcoursEnsemble(seance.ensemble_id),
                creneau : { h_debut: seance.creneau_debut, h_fin: seance.creneau_fin, jour: jour, id: seance.creneau_id },
                examinateur_id: seance.examinateur_id
            }
            this.candidat_epreuve_select = candidat_ensemble

            // Charge les créneaux dispos pour le popup
            this.getDisposExaminateur(seance.id, candidat.id, seance)

            // Peut être null si on est sur une épreuve de TP, on n'affiche pas l'info de l'examinateur dans ce cas là
            if (seance.examinateur_id) {
                const params: any =     {
                    typePoste: PosteType.TYPE_PEDAGOGIQUE,
                    typePassation: TypePassation.TYPE_PASSATION_ORAL,
                    typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
                    from: 'disponibilites'
                }

                params['filter-id'] = seance.examinateur_id
                this.$store.dispatch('examinateur/getExaminateurByID', params).then(() => {
                    this.showPopupGestionSeance = true
                })
            } else {
                this.showPopupGestionSeance = true
            }
        } else {
            this.showPopupInfo = true
        }
    }

    /**
     * Ferme la popup du message d'info pour démarrer l'édition
     */
    closeInfo () {
        this.$store.state.planification.error_planification = null
        this.showPopupInfo = false
        this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
        this.candidat_multi_serie = {}
        this.showPopupMultiSerieCandidat = false
    }

    /**
     * Met à jour le filtre équipe si on clique sur un tag
     */
    refreshFiltreEquipe (id: number, type: TypeEnsemble) {
        this.$store.state.planification.error_planification = null
        const filtre = { id: id, type: type }
        this.$emit('ensemble_id', filtre)
    }

    /**
     * Fermeture du popup de gestion d'une séance
     */
    closeGestionSeance () {
        // Reset des créneaux dispos la modification est terminée
        this.$store.state.planification.error_planification = null
        this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
        this.showPopupGestionSeance = false
        this.edition_seance = false
        this.seance_select = null
    }

    /** Retourne une string du ou des concours lié à l'ensemble */
    getConcoursEnsemble (ensemble_id: number) {
        // let chaine_concours = ''
        const ensemble_select = this.$store.state.ensemble.ensembles.find((e: any) => e.id === ensemble_id)

        return this.getEnsembleChaineConcours(ensemble_select)
        /*
        if (ensemble_select && ensemble_select.concour) {
            chaine_concours = ensemble_select.concour.name
        } else {
            chaine_concours = this.$props.chaine_full_concours
        }
        */

        // return chaine_concours
    }

    getEnsembleChaineConcours (ensemble: any) {
        if (ensemble?.concour?.name) {
            return ensemble.concour.name
        } else {
            if (ensemble?.groupeEpreuve?.epreuves?.length > 0) {
                const retour: string[] = []
                for (let i = 0; i < ensemble.groupeEpreuve.epreuves.length; i++) {
                    if (ensemble.groupeEpreuve.epreuves[i].epreuves && ensemble.groupeEpreuve.epreuves[i].epreuves.length > 0) {
                        for (let j = 0; j < ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                            retour.push(ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour.code)
                        }
                    } else {
                        retour.push(ensemble.groupeEpreuve.epreuves[i].concour.code)
                    }
                }
                return retour.join(', ') || ''
            } else {
                return this.$props.chaine_full_concours
            }
        }
    }

    /**
     * Retourne une title formaté pour affichage
     */
    getTitleFormat (seance: any) {
        let title = ''

        if (seance) {
            if (seance.centre) {
                title += seance.centre.name + '\n'
            }

            title += seance.epreuve_name + '\n' + seance.creneau_debut + ' - ' + seance.creneau_fin

            if (seance.etat === etat_seance.ETAT_DEMISSIONE) {
                title += '\n\n** Séance démissionnée **\n'
            }
        }
        return title
    }

    /**
     * Enregistrement de la sélection du créneau
     */
    saveCreneau (e: any) {
        // Sauvegarde du créneau pour le candidat
        this.$store.dispatch('planification/updateSeance', { seance_id: this.$store.state.planification.seance_id_select, creneau_id: Number(e.target.value), mode: 'ADD' }).then(() => {
            // Reset des créneaux dispos l'ajout est terminé
            this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
            this.$store.commit('planification/SET_SEANCE_ID_SELECT', null)
            this.seance_select = null
            this.$store.state.planification.error_planification = null
        })
    }

    /**
     * Initialisation du popup de gestion des séances pour un candidat sur des séries transverses
     */
    showMultiSerieCandidat (candidat: any) {
        this.$store.state.planification.error_planification = null
        this.candidat_multi_serie = candidat
        // Chargement des jours series
        this.$store.getters['planification/setJoursMultiSeries'](this.$store.state.serie.series, this.candidat_multi_serie.seances, true)
        this.showPopupMultiSerieCandidat = true
    }

    /**
     * @description Émet l'événement d'ouverture de la popup d'édition d'un candidat
     * @param {any} candidat_id - Id du candidat
     * @returns {void}
     */
    openEditCandidat(candidat_id: any): void {
        this.$emit('editCandidat', { id: candidat_id, tab: 'oraux' })
    }

    /**
     * Rafraichit les données
     */
    reloadDatas () {
        this.$emit('reload')
    }
}
