










































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PopupEditUser from '@/components/Administration/Users/PopupEditUser.vue'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer, formatDateVariante } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import { CandidatJobDescriptionStatus, getCandidatJobDescriptionStatus } from '@/types/CandidatJobDescription'
import { getJobDescriptionStatus } from '@/types/JobDescription'
import Formulaire from '@exatech-group/formulaire/src/Formulaire.vue'
import Back from '@/components/Tools/Back.vue'

@Component({
    methods: {
        getJobDescriptionStatus,
        formatDateVariante
    },
    components: {
        PopupEditUser,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay,
        PopupEditCandidat,
        Formulaire,
        Back
    },
    computed: {
        CandidatJobDescriptionStatus() {
            return CandidatJobDescriptionStatus
        },
        Ability() {
            return Ability
        },
        isLoading(): boolean {
            return this.$store.getters['candidatJobDescription/loading'] || this.$store.getters['jobProfileForm/loading']
        },
        hasError(): any {
            return this.$store.getters['candidatJobDescription/error'] || this.$store.getters['jobProfileForm/error']
        },
        canEdit(): boolean {
            const can = this.$store.getters['auth/can']
            if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
                return true
            }
            if (can(Ability.INTERV_OWN_ESTABLISHMENT)) {
                const session = this.$store.getters['session/sessions'].find((session: any) => session.id === this.$store.getters['auth/user_session_id'])
                const startAt = session.job_descriptions_start_at
                const endAt = session.job_descriptions_end_at

                if (startAt && endAt) {
                    const start = new Date(startAt)
                    const end = new Date(endAt)
                    const now = new Date()

                    if (now >= start && now <= end) {
                        return true
                    }
                }
            }
            return false
        },
        status(): any {
            const candidatJobDescriptionSelect = this.$store.getters['candidatJobDescription/candidatJobDescriptionSelect']
            const status = getCandidatJobDescriptionStatus(candidatJobDescriptionSelect.status)
            return {
                color: `text-${status.color}`,
                title: `${status.name} ${candidatJobDescriptionSelect?.updated_at ? `le ${formatDateVariante(candidatJobDescriptionSelect?.updated_at)}` : ''}`
            }
        },
        total(): number {
            return this.$store.getters['candidatJobDescription/meta']?.total || 0
        },
        reponsesFormulaire(): any {
            return this.$store.getters['candidatJobDescription/candidatJobDescriptionSelect']?.candidat?.candidatJob?.datas
        },
        urlRetour(): string {
            const params = JSON.parse(localStorage.getItem('candidatureParams') || JSON.stringify({}))
            return params['filter-working_establishment_id'] ? `/bourse_emploi/etablissements/${params['filter-working_establishment_id']}?tab=candidatures` : '/bourse_emploi/candidatures'
        },
        ...mapGetters('candidatJobDescription', ['candidatJobDescriptionSelect']),
        ...mapGetters('jobProfileForm', ['jobProfileFormSelect']),
        ...mapGetters('auth', ['authUser', 'can'])
    }
})

export default class ListeCandidatures extends Vue {
    processing = false
    showCandidatModal = false
    showJobDocument = false
    showConfirmEditStatus = false
    newStatus = null as CandidatJobDescriptionStatus | null

    documentToShow: any = {
        name: null,
        content: null
    }

    config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    @Watch('isLoading')
    onLoadingChange(value: boolean): void {
        this.processing = value
    }

    /**
     * @description Récupère l'index de la réclamation actuelle
     * @returns {number}
     */
    getIndexCurrentCandidature(): number {
        const candidatJobDescriptions = this.$store.getters['candidatJobDescription/candidatJobDescriptions']
        if (candidatJobDescriptions.length) {
            return candidatJobDescriptions.findIndex((candidature: any) => candidature.id === Number(this.$route.params.candidat_job_description_id))
        }
        return 0
    }

    /**
     * @description Navigation vers la réclamation précédente
     * @returns {void}
     */
    async previousCandidature(): Promise<void> {
        const previousCandidature = this.$store.getters['candidatJobDescription/candidatJobDescriptions'][this.getIndexCurrentCandidature() - 1] || null
        if (previousCandidature) {
            await this.$router.push('/bourse_emploi/candidatures/' + previousCandidature.id)
        }
    }

    /**
     * @description Navigation vers la réclamation suivante
     * @returns {Promise<void>}
     */
    async nextCandidature(): Promise<void> {
        // Charger plus de réclamations si on approche de la limite par page
        const meta = this.$store.getters['candidatJobDescription/meta']
        const modulo = (this.getIndexCurrentCandidature() + 1) % meta.per_page
        let nextCandidature = this.$store.getters['candidatJobDescription/candidatJobDescriptions'][this.getIndexCurrentCandidature() + 1] || null

        if (nextCandidature) {
            await this.$router.push('/bourse_emploi/candidatures/' + nextCandidature.id)
        } else if (modulo === 0 && meta.current_page < meta.last_page) {
            const params = JSON.parse(localStorage.getItem('candidatureParams') || JSON.stringify({}))
            params.page = meta.current_page + 1

            await this.$store.dispatch('candidatJobDescription/getMoreCandidatJobDescriptions', params)

            localStorage.setItem('candidatureParams', JSON.stringify(params))
            meta.current_page = params.page
            this.$store.commit('candidatJobDescription/SET_META', meta)

            nextCandidature = this.$store.getters['candidatJobDescription/candidatJobDescriptions'][this.getIndexCurrentCandidature() + 1] || null
            if (nextCandidature) {
                await this.$router.push('/bourse_emploi/candidatures/' + nextCandidature.id)
            }
        }
    }

    /**
     * @description Ouvre la modale de consultation des informations du candidat
     * @param {any} candidat - Candidat à consulter
     * @returns {void}
     */
    openCandidatModal(candidat: any): void {
        if (this.processing) {
            return
        }

        this.$store.commit('candidatJobDescription/SET_LOADING', true)
        this.$store.dispatch('candidat/getCandidat', { id: candidat.id })
            .then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                this.showCandidatModal = true
            })
            .finally(() => {
                this.$store.commit('candidatJobDescription/SET_LOADING', false)
            })
    }

    /**
     * @description Fermeture de la modale de consultation des informations du candidat
     * @returns {void}
     */
    closeCandidatModal() {
        this.showCandidatModal = false
    }

    /**
     * @description Ouverture de la modale de consultation d'un document de la fiche de poste
     * @param {any} job - Fiche de poste
     * @param {number} index - Index du document
     * @returns {Promise<void>}
     */
    async openJobDocument(job: any, index: number): Promise<void> {
        if (this.processing) {
            return
        }
        this.processing = true

        this.documentToShow.name = job.documents[index].name
        const response = await this.$store.dispatch('jobDescription/getJobDocument', {
            job_id: job.id,
            document_uuid: job.documents[index].uuid
        })
        this.documentToShow.content = base64ToArrayBuffer(response.data)
        this.showJobDocument = true
        this.processing = false
    }

    /**
     * @description Fermeture de la modale de consultation d'un document de la fiche de poste
     * @returns {void}
     */
    closeJobDocument(): void {
        this.showJobDocument = false
        this.documentToShow = {
            name: null,
            content: null
        }
    }

    /**
     * @description Ouverture de la confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    openConfirmEditStatus(): void {
        this.showConfirmEditStatus = true
    }

    /**
     * @description Confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    confirmEditStatus(): void {
        this.closeConfirmEditStatus()
        this.updateStatus(this.newStatus as CandidatJobDescriptionStatus, false)
    }

    /**
     * @description Fermeture de la confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    closeConfirmEditStatus(): void {
        this.showConfirmEditStatus = false
    }

    /**
     * @description Mise à jour du statut de la candidature
     * @param {CandidatJobDescriptionStatus} status - Statut de la candidature
     * @param {boolean} checkOtherCandidatures - Vérifier s'il n'existe pas d'autres candidatures sur le même poste ayant le statut "Accepté"
     * @returns {void}
     */
    updateStatus(status: CandidatJobDescriptionStatus, checkOtherCandidatures = true): void {
        if (this.processing) {
            return
        }

        this.newStatus = status
        if (checkOtherCandidatures && status === CandidatJobDescriptionStatus.STATUS_ACCEPTED) {
            const candidatJobDescription = this.$store.getters['candidatJobDescription/candidatJobDescriptionSelect']
            const otherCandidacies = candidatJobDescription.jobDescription.candidaciesValidated
                .filter((candidature: any) => candidature.id !== candidatJobDescription.id)

            if (otherCandidacies.length > 0) {
                this.openConfirmEditStatus()
                return
            }
        }

        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('candidatJobDescription/putCandidatJobDescriptionStatus', {
            id: this.$store.getters['candidatJobDescription/candidatJobDescriptionSelect'].id,
            status: status
        })
            .finally(() => {
                this.processing = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * storeDocumentReference
     * Cette fonction permet de stocker un document référence dans le formulaire en cours.
     * @param {string} titre - Titre du document
     * @param {any} data - Données du document à stocker
     * @returns une promesse résolue avec l'UUID du document stockée
     */
    async storeDocumentReference(titre: string, data: any) {
        const formdata = new FormData()
        formdata.set('document', data)
        formdata.set('name', titre)
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobProfileForm/storeDocumentReference', {
                    payload: formdata,
                    jobProfileForm_id: this.$store.getters['jobProfileForm/jobProfileFormSelect'].id
                })
                .then((response: any) => {
                    resolve({ id: response.data.data.uuid })
                })
        })
    }

    /**
     * getDocumentReference
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à récupérer
     * @returns une promesse résolue avec le document récupéré
     */
    getDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobProfileForm/getDocumentReference', {
                    jobProfileForm_id: this.$store.getters['jobProfileForm/jobProfileFormSelect'].id,
                    documentId: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * getDocument
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à récupérer
     * @returns une promesse résolue avec le document récupéré
     */
    getDocument(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('candidatJob/getDocument', {
                    candidatJob_id: this.$store.getters['candidatJobDescription/candidatJobDescriptionSelect'].candidat.candidatJob.id,
                    document_uuid: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * deleteDocumentReference
     * Cette fonction permet de supprimer un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à supprimer
     * @returns une promesse résolue avec la réponse de la suppression du document
     */
    deleteDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobProfileForm/deleteDocumentReference', {
                    jobProfileForm_id: this.$store.getters['jobProfileForm/jobProfileFormSelect'].id,
                    documentId: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    @Watch('$route.params.candidat_job_description_id', { immediate: true })
    async load(): Promise<void> {
        this.$store.commit('candidatJobDescription/SET_CANDIDAT_JOB_DESCRIPTION_SELECT', null)

        const candidatJobDescriptions = this.$store.getters['candidatJobDescription/candidatJobDescriptions']
        if (!candidatJobDescriptions.length) {
            await this.$store.dispatch('candidatJobDescription/getCandidatJobDescriptions', {
                page: 1,
                sort: 'name',
                direction: 'asc'
            })
        }
        await this.$store.dispatch('candidatJobDescription/getCandidatJobDescription', Number(this.$route.params.candidat_job_description_id))
        if (this.$store.getters['candidatJobDescription/candidatJobDescriptionSelect']) {
            if (this.$store.getters['candidatJobDescription/candidatJobDescriptions'].length === 0) {
                const params = {
                    ...JSON.parse(localStorage.getItem('candidatureParams') || JSON.stringify({})),
                    page: 1
                }
                await this.$store.dispatch('candidatJobDescription/getCandidatJobDescriptions', params)

                while (this.getIndexCurrentCandidature() === -1) {
                    params.page++
                    const response = await this.$store.dispatch('candidatJobDescription/getMoreCandidatJobDescriptions', params)
                    if (response.data.data.length === 0) {
                        break
                    }

                    const meta = this.$store.getters['candidatJobDescription/meta']
                    meta.current_page = params.page
                    this.$store.commit('candidatJobDescription/SET_META', meta)
                }
            }
        }

        await this.$store.dispatch('jobProfileForm/getJobProfileForms')
        const jobProfileForms = this.$store.getters['jobProfileForm/jobProfileForms']
        if (jobProfileForms.length > 0) {
            this.$store.commit('jobProfileForm/SET_JOB_PROFILE_FORM_SELECT', jobProfileForms[0])
        }
    }
}
