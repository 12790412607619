































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ModuleDossiersAcademiques from '@/views/Modules/ModuleDossiersAcademiques.vue'
import ModuleDossiersAdministratifs from '@/views/Modules/ModuleDossiersAdministratifs.vue'
import ModuleReponseIntervenant from '@/views/Modules/ModuleReponseIntervenant.vue'
import ModuleContrats from '@/views/Modules/ModuleContrats.vue'
import ModuleGestionCentre from '@/views/Modules/ModuleGestionCentre.vue'
import ModuleConventions from '@/views/Modules/ModuleConventions.vue'
import ModuleSignatureFicheHoraire from '@/views/Modules/ModuleSignatureFicheHoraire.vue'
import ModuleGestionOral from '@/views/Modules/ModuleGestionOral.vue'
import ModuleGestionFicheHoraire from '@/views/Modules/ModuleGestionFicheHoraire.vue'
import TDBGestionCentre from '@/views/Modules/TDBGestionCentre.vue'
import TDBObservateur from '@/views/Modules/TDBObservateur.vue'
import TDBExaminateur from '@/views/Modules/TDBExaminateur.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { Etat } from '@/types/DossierAdministratif'
import { PosteType } from '@/types/Poste'
import { getTypePassationSpec } from '@/types/Epreuve'

@Component({
    components: {
        ModuleDossiersAcademiques,
        ModuleDossiersAdministratifs,
        ModuleReponseIntervenant,
        ModuleContrats,
        ModuleGestionCentre,
        ModuleConventions,
        ModuleSignatureFicheHoraire,
        ModuleGestionOral,
        ModuleGestionFicheHoraire,
        TDBGestionCentre,
        TDBObservateur,
        TDBExaminateur,
        ItemIntervenant
    },
    computed: {
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ]),
        ...mapState('auth', ['multi_session']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('epreuve', ['epreuvesCorrections', 'loading']),
        ...mapState('session', ['sessionSelect']),
        ...mapGetters('session', ['get_sessions_intervenants']),
        ...mapGetters('dossieradministratif', ['contrats']),
        ...mapState('user', ['dossieracademiqueId', 'dossieradministratifId', 'hasContrat', 'centreId', 'conventionPublished', 'menu_ready']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('poste', ['postes']),
        onlyExt() {
            // Sert à afficher correctement le dashboard quand un utilisateur n'a seulement que les droits externes
            const user = this.$store.getters['auth/authUser']
            if (user) {
                const abilities = [ ...new Set(user?.abilities.map((ability: any) => ability.name)) ]
                if (abilities.length > 3) {
                    return false
                } else if (this.$store.getters['auth/can'](Ability.EXT_RESULTS_VIEW)) {
                    return true
                }
            }
            return false
        }
    },
    methods: {
        ...mapActions('dossieracademique', ['getDossiersAcademiques']),
        ...mapActions('dossieradministratif', ['getDossiersAdministratifs']),
        ...mapActions('centre', ['getCentres'])
    }
})
export default class ModuleIntervenants extends Vue {
    @Prop() user?: any;
    @Prop() reload?: boolean;

    getTypePassationSpec = getTypePassationSpec

    new_tdb = true
    count_elements = -1
    hasDroitSps = []
    hasDroitAdm = []
    hasDroitSignatureFicheHoraire = []

    etat_vierge = Etat.ETAT_VIERGE
    etat_depose = Etat.ETAT_DEPOSE

    Etat = Etat

    RH_ADM_DOS_OWN_MANAGE = Ability.RH_ADM_DOS_OWN_MANAGE
    RH_SPS_DOS_OWN_MANAGE = Ability.RH_SPS_DOS_OWN_MANAGE
    RH_FICHEHORAIRE_OWN_SIGN = Ability.RH_FICHEHORAIRE_OWN_SIGN
    INTERV_PLANIF_OWN = Ability.INTERV_PLANIF_OWN
    INTERV_OBSERVATEUR = Ability.INTERV_OBSERV_MATIERE_OWN
    INTERV_SUPPLEANT_OWN = Ability.INTERV_SUPPLEANT_OWN
    INTERV_OWN_ESTABLISHMENT = Ability.INTERV_OWN_ESTABLISHMENT
    EXT_RESULTS_VIEW = Ability.EXT_RESULTS_VIEW

    showSelectionSession = false

    hasDossiersAcademique = false
    hasDossiersAdministratif = false
    hasContrats = false
    hasCDC = false
    hasConventions = false
    hasCentres = false
    hasRESP = false

    sessions_intervenants = []
    selected_session: any = null

    user_centres: any = []
    user_centre_select: any = null

    @Watch('user_centre_select')
    wUser_centre_select() {
        this.$store.state.user.centreId = this.user_centre_select
        // Maj le centreSelect
        const centreS = this.$store.state.centre.centres.find((c: any) => c.id === this.user_centre_select)
        this.$store.commit('centre/SET_CENTRE_SELECT', centreS)
        this.setCentreInSessionStorage()
    }

    @Watch('reload')
    reloadDashboard () {
        if (this.$props.reload) {
            this.setVignettes()
        }
    }

    @Watch('centreId')
    @Watch('dossieracademiqueId')
    @Watch('dossieradministratifId')
    @Watch('hasContrat')
    @Watch('conventionPublished')
    setVignetteFresh () {
        if (this.$store.state.user.dossieradministratifId !== null) {
            this.initEspaceIntervenantSuiteChargementDissierAdministratifs()
        }
        if (this.$store.state.auth.user !== null && !this.$store.state.epreuve.loading) {
            this.initEspaceIntervenant()
        }
        this.setVignettes()
    }

    @Watch('hasRESP')
    setCentreIfNotExist () {
        if (this.hasRESP && this.$store.state.user.centreId === null) {
            this.setCentreSelect()
        }
    }

    @Watch('hasCDC')
    setConventionPublished () {
        if (this.hasCDC && this.$store.state.user.centreId === null) {
            this.setCentreSelect()
        } else {
            this.$store.state.user.conventionPublished = this.$store.state.centre.centres[0] ? this.$store.state.centre.centres[0].convention_published_at : null
        }
    }

    setCentreSelect () {
        let centre_select = 0
        this.$store.dispatch('centre/getCentres').then(() => {
            if (this.$store.state.user.centreId !== null) {
                this.user_centre_select = this.$store.state.user.centreId
                centre_select = this.$store.state.centre.centres.findIndex((centre: { id: any })  => centre.id === this.user_centre_select)
            } else {
                this.user_centre_select = this.$store.state.centre.centres[0].id
            }
            this.user_centre_select = this.$store.state.centre.centres[0].id
            this.user_centres = this.$store.state.centre.centres
            this.$store.state.user.centreId = this.$store.state.centre.centres[centre_select] ? this.$store.state.centre.centres[centre_select].id : null
            this.$store.state.user.centreTypePassation = this.$store.state.centre.centres[centre_select] ? this.$store.state.centre.centres[centre_select].type_passation : null
            this.$store.state.user.centre_organisation_ecrit_validated = this.$store.state.centre.centres[centre_select] ? this.$store.state.centre.centres[centre_select].nb_intervenants_validated_at : null
            this.$store.state.user.conventionPublished = this.$store.state.centre.centres[centre_select] ? this.$store.state.centre.centres[centre_select].convention_published_at : null

            if (centre_select === -1) {
                centre_select = 0
            }
            if (this.$store.state.centre.centres[centre_select]) {
                this.$store.commit('centre/SET_CENTRE_SELECT', this.$store.state.centre.centres[centre_select])
                this.$store.commit('user/SET_FICHES_INTERVENANTS_PUBLISHED', this.$store.state.centre.centres[centre_select].fiches_intervenants_published_at)
            }
        })
    }

    update_user_centreId() {
        this.$store.state.user.centreId = this.user_centre_select
    }

    getCentreInSessionStorage () {
        const userId = this.$store.getters['auth/authUser'].id
        if (window.sessionStorage && window.sessionStorage['centreUserSelect' + userId] && window.sessionStorage['centreUserSelect' + userId] !== '') {
            this.user_centre_select = parseInt(window.sessionStorage['centreUserSelect' + userId])
        }
    }

    setCentreInSessionStorage () {
        const userId = this.$store.getters['auth/authUser'].id
        window.sessionStorage['centreUserSelect' + userId] = this.$store.state.user.centreId
    }

    setVignettes () {
        // Check affichage des vignettes dashboard
        if (this.$store.getters['user/centreId'] !== null && this.$store.getters['auth/is_intervenant_centre']) {
            this.hasCDC = true
            this.hasRESP = true
            this.count_elements++
        }

        if (this.$store.getters['user/conventionPublished'] !== null) {
            this.hasConventions = true
            this.count_elements++
        } else {
            this.hasConventions = false
        }

        if (this.$store.getters['user/dossieradministratifId'] !== null) {
            this.hasDossiersAdministratif = true
            this.count_elements++
        } else {
            this.hasDossiersAdministratif = false
        }

        if (this.$store.getters['user/dossieracademiqueId'] !== null) {
            this.hasDossiersAcademique = true
            this.count_elements++
        } else {
            this.hasDossiersAcademique = false
        }

        if (this.$store.getters['user/hasContrat']) {
            this.hasContrats = true
            this.count_elements++
        } else {
            this.hasContrats = false
        }

        if (this.$store.getters['auth/can'](this.INTERV_OBSERVATEUR) || this.$store.getters['auth/can'](this.EXT_RESULTS_VIEW)) {
            this.count_elements++
        }

        if (this.hasDroitSignatureFicheHoraire && this.hasDroitSignatureFicheHoraire.length !== 0) {
            this.count_elements++
        }

        // Cas spécifique d'un intervenant Examinateur
        if (this.$store.getters['auth/can'](this.INTERV_PLANIF_OWN)) {
            this.count_elements++
        }
    }

    initEspaceIntervenant () {
        const params: any = {}
        this.hasDroitSps = this.$store.state.auth.user.abilities.find((a: any) => a.name === Ability.RH_SPS_DOS_OWN_MANAGE && a.scope === this.$store.state.auth.user_session_id)
        this.hasDroitAdm = this.$store.state.auth.user.abilities.find((a: any) => a.name === Ability.RH_ADM_DOS_OWN_MANAGE && a.scope === this.$store.state.auth.user_session_id)

        if ((this.hasDroitSps || this.hasDroitAdm) && this.$store.state.auth.user_session_id) {
            if (!this.$store.state.epreuve.loading) { //  && this.$store.state.epreuve.epreuvesCorrections.length === 0
                this.$store.dispatch('epreuve/getEpreuvesCorrections', { isPrecedente: false, filters: params })
            }

            // Académique
            if (this.hasDroitSps && this.$store.state.user.dossieracademiqueId === null && !this.$store.state.dossieracademique.loading) {
                this.$store.dispatch('dossieracademique/getDossiersAcademiques').then(() => {
                    const da = this.$store.getters['dossieracademique/dossierAcademiqueByUserId'](this.$store.state.auth.user.id)
                    if (da) {
                        this.$store.state.user.dossieracademiqueId = this.$store.state.auth.user.id.courant ? da.courant.id : da.id ? da.id : null
                    }

                })
            }

            // Administratif
            if (this.hasDroitAdm && this.$store.state.user.dossieradministratifId === null && this.$store.state.dossieradministratif.dossiersAdministratifs.length === 0 && !this.$store.state.dossieradministratif.loading) {
                this.$store.dispatch('dossieradministratif/getDossiersAdministratifs').then(() => {
                    this.initEspaceIntervenantSuiteChargementDissierAdministratifs()
                })
            } else if (this.hasDroitAdm && this.$store.state.user.dossieradministratifId === null) {
                this.initEspaceIntervenantSuiteChargementDissierAdministratifs()
            }
        }

        this.hasDroitSignatureFicheHoraire = this.$store.state.auth.user.abilities.find((a: any) => a.name === Ability.RH_FICHEHORAIRE_OWN_SIGN)
        this.setVignettes()
    }

    initEspaceIntervenantSuiteChargementDissierAdministratifs () {
        this.$store.state.user.dossieradministratifId = (this.$store.state.dossieradministratif.dossiersAdministratifs[0]?.etat || 0) === Etat.ETAT_INVISIBLE ? null : this.$store.state.dossieradministratif.dossiersAdministratifs[0].id
        // Check si des contrats sont associés
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length !== 0 && this.$store.state.dossieradministratif.dossiersAdministratifs[0] && this.$store.state.dossieradministratif.dossiersAdministratifs[0].contrats.length !== 0) {
            this.$store.commit('dossieradministratif/SET_CONTRATS', this.$store.state.dossieradministratif.dossiersAdministratifs[0].contrats)
            this.$store.state.user.hasContrat = true
            this.$store.commit('user/SET_HAS_POSTE_ACA', false)
            // Chargement des postes
            if (this.$store.state.poste.postes.length === 0 && !this.$store.state.poste.loading) {
                this.$store.dispatch('poste/getAllPostes').then(() => {
                    this.check_has_poste_aca()
                })
            } else {
                this.check_has_poste_aca()
            }
        }
    }

    // Change le flag de has_poste_aca
    check_has_poste_aca () {
        // Check des postes sur les contrats
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length !== 0) {
            const dossier_adm = this.$store.state.dossieradministratif.dossiersAdministratifs[0]
            for (const c in dossier_adm.contrats) {
                for (const b in dossier_adm.contrats[c].bareme_remunerations) {
                    const poste_check = this.$store.state.poste.postes.find((p: any) => p.id.toString() === dossier_adm.contrats[c].bareme_remunerations[b].poste_id.toString())
                    if (poste_check && poste_check.type === PosteType.TYPE_PEDAGOGIQUE) {
                        this.$store.commit('user/SET_HAS_POSTE_ACA', true)
                    }
                }
            }
        }
    }

    // Selection d'une session
    selectSession (session_id: any) {
        this.selected_session = session_id
    }

    // Sauvegarde de la session selectionnée
    save_session_select () {
        window.sessionStorage.setItem('user_session_id', this.selected_session.toString())
        const session_sel = this.sessions_intervenants.find((s: any) => s.id === this.selected_session)
        this.$store.commit('auth/SET_USER_SESSION_ID', this.selected_session)
        this.$store.commit('auth/SET_MULTI_SESSION', false)
        this.$store.commit('session/SET_SESSION_SELECT', session_sel)
        this.showSelectionSession = false
        if (this.$store.getters['auth/is_intervenant_centre']) {
            this.setCentreSelect()
        }

        if (this.$store.state.auth.user !== null && !this.$store.state.epreuve.loading) {
            this.initEspaceIntervenant()
        }
    }

    // Déconnexion
    logout ()
    {
        this.$store.dispatch('auth/logout').then(() =>
        {
            this.$store.dispatch('reset')
        })
    }

    beforeMount() {
        this.getCentreInSessionStorage()
    }

    mounted () {
        this.initData()
    }

    initData() {
        if (this.$store.state.auth.user_session_id && this.$store.getters['auth/is_intervenant_centre']) {
            this.setCentreSelect()
        }
        if (this.$store.state.auth.multi_session) {
            // Récupère les sessions auxquelles l'intervenant a droit
            this.sessions_intervenants = this.$store.getters['session/get_sessions_intervenants'](this.$store.state.auth.user.abilities)
            this.showSelectionSession = true
        } else {
            if (this.$store.state.auth.user !== null && !this.$store.state.epreuve.loading) {
                this.initEspaceIntervenant()
            }
        }
    }
}
