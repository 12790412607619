

























































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone, dateDuJourFoDocument, formatDate, formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import validationDemission from '@/components/Demissions/popupValidationDemission.vue'
import { state_demission, type_demission } from '@/types/Demission'
import PopupEditCandidat                from '@/components/Candidat/PopupEditCandidat.vue'
import PopupCreerDemission from '@/components/Demissions/popupCreerDemission.vue'
import { TypeEnsemble } from '@/types/Ensemble'

/**
 * Composant contenant l'ensemble des démissions des épreuves facultatives
*/
@Component({
    computed: {
        ...mapGetters('demission', ['demissions_epreuves_fac', 'loading_demission', 'totalRows', 'lastPage', 'totalPage', 'meta', 'getCptDemissions_epreuve']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        PopupEditCandidat,
        PopupCreerDemission,
        validationDemission
    }
})
export default class DemissionsLangueFacultative extends Vue {
    nb_declarees = 0
    nb_validees = 0
    showModalValidationDemission?: boolean = false
    Ability = Ability
    totalDemissionsFiltered = 0 // pour l'affichage du total réel contenu dans le tableau des demissions affiché (filtrés et non filtrés)

    // Ensemble des colonnes du tableau de démissions
    genericfields = [
        { key: 'etatEdit',                     label: '',                       sortable: false,    class: '', type: 'action' },
        { key: 'code',                         label: 'Code',                   sortable: true,     class: '', type: 'text' },
        { key: 'name',                         label: 'Identité',               sortable: true,     class: '', type: 'text' },
        { key: 'filiere',                      label: 'Filière',                sortable: false,    class: 'text-start', type: 'text' },
        { key: 'equipes_inter',                label: 'Équipe',                 sortable: false,    class: 'text-center', type: 'text' },
        { key: 'equipes_par_epreuve',          label: 'Langue',                 sortable: false,    class: 'text-start', type: 'text' },
        { key: 'equipes_tp',                   label: 'TP',                     sortable: false,    class: 'text-start', type: 'text' },
        { key: 'serie',                        label: 'Série',                  sortable: true,     class: 'text-center', type: 'text' },
        { key: 'epreuve_fac',                  label: 'Épreuve',                sortable: false,    class: 'text-start', type: 'text' },
        { key: 'date_planifiee',               label: 'Planifiée le ',          sortable: true,     class: 'text-start', type: 'text' },
        { key: 'date_demande_epreuve',         label: 'Demandée le ',           sortable: true,     class: 'text-start', type: 'text' },
        { key: 'epreuveFac_demission',         label: 'Validation',             sortable: true,     class: 'text-center', type: 'text' }
    ]

    selectedDemission = []
    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;
    demissionsAssoc: Array<any> = []

    filtres:    any         = []
    dataForTab: Array<any>  = []
    showModalEditCandidat?:          boolean = false
    showModalCreerDemission = false
    type_demission = type_demission

    @Watch('demissions_epreuves_fac')
    initDatasDemissions () {
        this.initDatas()
        this.initStatsDemissions()
    }

    @Watch('meta')
    majInfosTable () {
        // On récupère le nombre total de demissions filrés depuis les infos des Metadonnées
        if (this.$store.state.demission.meta !== null) {
            if (this.totalDemissionsFiltered !== this.$store.state.demission.meta.total) {
                this.totalDemissionsFiltered = this.$store.state.demission.meta.total
            }
        } else {
            this.totalDemissionsFiltered = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.nb_declarees = 0
        this.nb_validees = 0
        this.$store.state.demission.meta.total = 0
        this.load()
    }

    exporterDemissions () {
        let fileName = 'export_demission_langue_facultative'
        fileName += '_' + dateDuJourFoDocument() + '.xlsx'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('demission/exportDemission', 'langue_facultative')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        const can = this.$store.getters['auth/can'](Ability.ORAL_SUIVI_MANAGE)
        const icone = checkIcone(Ability.ORAL_SUIVI_MANAGE, can)
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                // Inscriptions concours
                const concours_result = result?.concours
                    ?.map((concour: any) => concour.name)
                    ?.join(', ') || ''

                const identity = result.name && result.first_name ? result.name + ' ' +  result.first_name : ' - '
                const epreuve_fac = result.epreuveFac[0]
                const validation = this.getPuceColorValidation(epreuve_fac)
                const date_demande = formatDate(epreuve_fac.demande_demission_at)
                const serie = result.serie_id ? this.$store.state.serie.series.find((serie: any) => serie.id === result.serie_id) : null
                const serie_name = serie ? serie.name : '-'
                const seance = result.seances.find((s: any) => s.epreuve_id === epreuve_fac.id)
                const datePlanifiee = seance && seance.creneau ? formatDateSinTime(seance.creneau.jour) + ' à ' + formatDateHoursMinutes(seance.creneau.h_debut) : '-'
                /* infoCandidat : recup des séries, épreuves etc preformaté pour affichage dans la popUp + toutes les infos candidat dans l objet candidat */
                const infoCandidat = {
                    candidat: result,
                    concours: concours_result,
                    identity: identity,
                    epreuve_fac: epreuve_fac,
                    date_planifiee: datePlanifiee,
                    date_demande: date_demande,
                    serie: serie_name,
                    filiere:result.concour,
                    etat: epreuve_fac.demission,
                    type_demission: type_demission.EPREUVES_FAC
                }

                const equipe = result.ensembles.find((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT)
                const equipe_langue = result.ensembles.find((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE)
                const equipe_tp = result.ensembles.find((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP)

                const line = [
                    { label: icone.label,       item: infoCandidat, type: 'action',  typeAction: 'edit',    class: 'commons_first_action_button btn_action_ligne', icon:icone.icon, disabled: false },
                    { label: 'Fiche candidat',  item: result.id,    type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action', text: result.code },
                    { label: 'Fiche candidat',  item: result.id,    type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action', text: identity },
                    { label: '',            item: result.concour.name,  type: 'text',    typeAction: null,  class: 'text-start' },
                    { label: '',            item: equipe ? equipe.name : '-',                  type: 'text', typeAction: null, class: 'text-center' },
                    { label: '',            item: equipe_langue ? equipe_langue.name : '-',    type: 'text', typeAction: null, class: 'text-start' },
                    { label: '',            item: equipe_tp ? equipe_tp.name : '-',            type: 'text', typeAction: null, class: 'text-start' },
                    { label: '',            item: serie_name,            type: 'text',    typeAction: null,  class: 'text-center' },
                    { label: '',            item: epreuve_fac.name, type: 'text',    typeAction: null,  class: 'text-start' },
                    { label: '',            item: datePlanifiee,    type: 'text',    typeAction: null,  class: 'text-start' },
                    { label: '',            item: date_demande,     type: 'text',    typeAction: null,  class: 'text-start' },
                    { label: '',            item: validation,       type: 'icons',   typeAction: null,  class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        this.filtres = []
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const options_avancement = []
        options_avancement.push({ index: state_demission.DEMISSION_TRAITE_OUI, name: 'Démissions acceptées' })
        options_avancement.push({ index: state_demission.DEMISSION_TRAITE_NON, name: 'Démissions non prises en compte' })
        options_avancement.push({ index: state_demission.DEMISSION_DEMANDE, name: 'Démissions non traitées' })
        // options_avancement.push({ index: state_demission.DEMISSION_DEMANDE_VUE, name: 'Démissions qui ne seront pas traitées' })

        this.filtres =
        [
            { libelle: 'Code',                    defautOptionlibelle: 'Rechercher un',   model: 'code',                 value: '', index: 'code',       datas: null,        loading: this.$store.state.demission.loading_demission, options: { type: 'form',      fieldsKey: 'code', strict: true } },
            { libelle: 'Nom',                     defautOptionlibelle: 'Rechercher un',   model: 'name',                 value: '', index: 'name',       datas: null,        loading: this.$store.state.demission.loading_demission, options: { type: 'form',      fieldsKey: 'name' } },
            { libelle: 'Filière',                 defautOptionlibelle: 'Rechercher une',  model: 'concour_id',           value: '', index: 'filiere',    datas: options_filieres,  loading: this.$store.state.demission.loading_demission, options: { type: 'deroulant', fieldsKey: 'filiere' } },
            { libelle: 'Validation',              defautOptionlibelle: 'Rechercher une',  model: 'epreuveFac_demission',           value: '', index: 'epreuveFac_demission',    datas: options_avancement,  loading: this.$store.state.demission.loading_demission, options: { type: 'deroulant', fieldsKey: 'epreuveFac_demission' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            const demissions = this.$store.state.demission.demissions_epreuves_fac
            let selectedCandidat = null

            switch (paParams[0])
            {
                case 'edit':
                    this.validationDemission(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'editCandidat':
                    // Récupération de l'étab by ID
                    selectedCandidat = demissions.filter((r: any) => r.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.editCandidat(selectedCandidat)
                    }

                    break
                default:
                    break
            }
        }
    }

    /**
     * Récupération sur le serveur de la session active
     */
    loadSessionActiveIfNotExists (force = false)
    {
        return new Promise((resolve) =>
        {
            if (!this.$store.getters['session/sessionSelect'] || force)
            {
                this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] }).then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des séries de la session
     */
    loadSeriesSessionActiveIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // on récupère systématiquement les séries de la session active
            this.$store.dispatch('serie/getSeries').then(() =>
            {
                resolve(true)
            })
        })
    }


    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // Check si le chargement est sur la session active
            let session_active_load = false
            if (this.$store.state.session.sessionSelect && this.$store.getters['concour/banques'].length !== 0 &&
            this.$store.state.session.sessionSelect.id === this.$store.getters['concour/banques'][0].session_id) {
                session_active_load = true
            }

            if (this.$store.getters['concour/banques'].length === 0 || !session_active_load)
            {
                this.$store.dispatch('concour/getConcoursActifs').then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }

    /**
     * Initialisation des données candidat affichées dans le tableau ainsi que les compteurs
     */
    initDatas ()
    {
        this.demissionsAssoc = this.$store.state.demission.demissions_epreuves_fac
        this.setDataForGenericTab(this.demissionsAssoc)
        if (this.filtres.length === 0) {
            // this.setFiltersForGenericTab()
        }
    }


    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler (params: any)
    {
        this.$store.dispatch('demission/getMoreDemissions', { type: type_demission.EPREUVES_FAC, params: params })
    }

    /**
     * Appel des datas avec un sort en paramètres
     */
    filtreSortHandler (params: any)
    {
        this.$store.dispatch('demission/getDemissions', { type: type_demission.EPREUVES_FAC, params: params })
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalValidationDemission ()
    {
        this.showModalValidationDemission = false
        this.selectedDemission = []
        this.initDatas()
        this.initStatsDemissions()
    }

    /**
     * ouverture de la modale d'édition candidat
     */
    initShowModalValidationDemission ()
    {
        this.showModalValidationDemission = true
    }

    /** Puce de l'état de la démission */
    getPuceColorValidation(result: any) {
        let class_affichage = 'text-tertiary'
        let title = ''
        if (result && result.demission) {
            switch (true) {
                case result.demission === state_demission.DEMISSION_TRAITE_OUI :
                    class_affichage = 'text-success'
                    title = 'Démission acceptée'
                    break
                case result.demission === state_demission.DEMISSION_TRAITE_NON:
                    class_affichage = 'text-secondary'
                    title = 'Démission non prise en compte'
                    break
                case result.demission === state_demission.DEMISSION_DEMANDE:
                    class_affichage = 'text-tertiary'
                    title = 'Nouvelle demande de démission'
                    break
                case result.demission === state_demission.DEMISSION_DEMANDE_VUE:
                    class_affichage = 'text-info'
                    title = 'Démission consultée mais non traitée'
                    break
            }
        }
        return [{ name: 'circle', class: class_affichage, title: title }]
    }

    /** popUpValidation demisssion  */
    validationDemission(demission: any) {
        this.selectedDemission = demission
        this.initShowModalValidationDemission()
    }

    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionné
     * et affichage de la modale
     */
    editCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamation/SET_LOADING', true) // on lance le loading
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params =
                {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditCandidat = true
                this.$store.commit('reclamation/SET_LOADING', false) // on lance le loading
            })
        }
    }

    /**
     * Fermeture de la modale d'édition d'un candidat
     */
    reinitShowModalEditCandidat ()
    {
        this.showModalEditCandidat = false
    }

    /**
     * Ajouter une démission
     */
    openCreerDemission ()
    {
        this.showModalCreerDemission = true
    }

    /**
     * Fermeture de la modale d'ajout d'une démission'
     */
    reinitShowModalCreerDemission ()
    {
        this.showModalCreerDemission = false
    }

    /**
     * Intitialisation des statistiques démission concours
     */
    initStatsDemissions ()
    {
        this.nb_declarees  = this.$store.getters['demission/getCptDemissions_epreuve'].cptDeclarees
        this.nb_validees   = this.$store.getters['demission/getCptDemissions_epreuve'].cptValidees
    }

    load () {
        // Si la session active n'est pas chargée, alors on la récupère
        this.loadSessionActiveIfNotExists(true).then(() => {
            // Récupération des séries de la session active
            this.loadSeriesSessionActiveIfNotExists().then(() => {
                // Si aucun concours n'as été chargé, alors on les récupère également
                this.loadConcoursIfNotExists().then(() => {
                    // Chargement des mesures d'handicap
                    this.initDatas()
                    this.setFiltersForGenericTab()
                })
            })
        })
    }

    /**
     * Au montage du composant, on charge les démissions et les concours si cela est nécéssaire
     */
    mounted ()
    {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
