




























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import CapacitesPhaseSuivante from '@/components/DefinitionDesBarres/CapacitesPhaseSuivante.vue'
import Comparaisons from '@/components/DefinitionDesBarres/Comparaisons.vue'
import Back from '@/components/Tools/Back.vue'
import store from '@/store'
import { dateisSameOrBefore, formatDateVariante, getFileNameFromHeader } from '@/utils/helpers'
import _, { debounce } from 'lodash'
import { BarRuleType, BarTypePass } from '@/types/Barre'



import Echo from 'laravel-echo'

import Pusher from "pusher-js"


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('phase', ['phases']),
        ...mapState('definitionDesBarres', ['old_saisie', 'new_saisie']),
        ...mapGetters('definitionDesBarres', [
            'tableauDesConcours',
            'meta',
            'links',
            'loading',
            'error',
            'currentPage',
            'lastPage',
            'totalPage',
            'totalRows',
            'work_in_progress'
        ])
    },
    components: {
        ExaGenericTable,
        FontAwesomeIcon,
        CapacitesPhaseSuivante,
        Comparaisons,
        Back
    },
    beforeRouteLeave(to, from, next) {
        if (store.getters['definitionDesBarres/work_in_progress']) {
            const result = confirm('Vous allez quitter la page. Des saisies ne sont pas sauvegardées. Souhaitez-vous les enregistrer avant de continuer votre navigation ?')
            if (result) {
            // TODO : Appel de la sauvegarde avant de poursuivre
                next();
            }
        } else {
            next();
        }

    }
})
export default class DefinirBarresConcours extends Vue {

    // --------------------------------------------------------- var

    Ability = Ability
    formatDateVariante = formatDateVariante

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    filtres: any = []
    dataForTab: Array<any> = []
    genericfields: Array<any> = []
    params: any = null
    exportingIsWorking = false
    showCapacites = false
    showComparaisons = false
    phase_id_select = null
    showValidationBarres = false
    showAlerteSauvegarde = false
    valide = 1
    last_save: any = null

    barres_actives: Array<any> = []
    ranking_groups: Array<any> = []

    hiddenBarres = [
        BarTypePass.BAR_TYPE_ANONYMOUS
    ]

    togglePopupHistoriqueConcourPhase = false
    errorKeeper = null

    historiqueConcourPhase: any = null

    showParametrageExportation = false
    selected_tab = 'comparaison'

    saisieIsOpen = true
    showModalSwitchSaisie = false
    switchSaisieKey = 'switchSaisie_0'

    firstLoading = false

    phases_options: Array<any> = []
    id_phase_suivante = null

    dataToValidate: any = null

    showValidateInvalidateBarre = false

    tableauDesConcoursValidables: Array<any> = []

    validationEnCours = false

    // -------------------------------------------------------------

    // --------------------------------------------------- pour test

    modeMonoColonneBarre = false

    // -------------------------------------------------------------

    // -------------------------------------------------------socket
    listenerOpen = false
    pusher: any = null
    echo: any = null

    disconnect () {
        if(!this.echo) {return}
        this.echo.channel('ping').stopListening('Pong')
        this.echo.disconnect()
        this.echo =  null
    }

    connect () {
        this.pusher = new Pusher(process.env.VUE_APP_REVERB_KEY,{
            cluster: '',
            wsHost: process.env.VUE_APP_REVERB_HOST,
            wsPort: process.env.VUE_APP_REVERB_PORT,
            wssPort: process.env.VUE_APP_REVERB_PORT,
            forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
            enabledTransports: ['ws', 'wss']
        })
        this.echo = new Echo({
            broadcaster: 'reverb',
            key: process.env.VUE_APP_REVERB_KEY,
            wsHost: process.env.VUE_APP_REVERB_HOST,
            wsPort: process.env.VUE_APP_REVERB_PORT,
            wssPort: process.env.VUE_APP_REVERB_PORT,
            forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
            enabledTransports: ['ws', 'wss']
        })
        console.log('pusher', this.pusher)
        console.log('echo', this.echo)
    }

    switchListener () {
        this.listenerOpen = !this.listenerOpen

        if(this.listenerOpen) {
            this.connect()

            this.echo.channel('ping').listen('Pong', (e: any) => {
                console.log('e', e)


            })

        } else {
            // this.echo.stopListen('monEvent')
            this.disconnect()
        }

    }

    // -------------------------------------------------------------

    // -------------------------------------------------- historique

    /**
     * Toggle le popup d'historique des validations / invalidations des barres
     * @returns {void}
     */
    setTogglePopupHistoriqueConcourPhase(concourphase_id: any): void {
        this.errorKeeper = null
        this.historiqueConcourPhase = null
        if(concourphase_id) {
            this.$store.dispatch('definitionBarresListeNotesAdmission/getHistoriqueConcourPhase', {concourphase_id: concourphase_id}).then((response) => {
                this.historiqueConcourPhase = response.data.data
                this.$store.commit('definitionDesBarres/SET_ERROR', null)
                this.togglePopupHistoriqueConcourPhase = true
            })
        } else {
            this.togglePopupHistoriqueConcourPhase = false

        }
    }

    getStringBarresHistory(data: any) {
        const retour = []
        for(const barre of data.barres) {
            if(!this.hiddenBarres.includes(barre.type)) {
                for(const threshold of barre.thresholds) {
                    retour.push((data.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, data.bar_rules).name : barre.name) + ' : <b>' + (threshold.value ? threshold.value : '-') + '</b>')
                }
            }
        }
        return retour
    }

    /**
     * Retourne la liste passée en paramètre ordonnée par created_at descendant
     * @param {any} liste - Liste à ordonner
     * @returns {any}
     */
    orderedListeByCreatedDateDesc(liste: any): any {
        return _.orderBy(liste, 'created_at', 'desc')
    }

    // -------------------------------------------------------------


    // -------------------------------------------------- eportation

    closeParametrageExportation () {
        this.showParametrageExportation = false
    }

    openParametrageExportation () {
        this.showParametrageExportation = true
    }

    // TODO : Exporter
    // -> Cabler l'export
    exporter () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Génération du document en cours ...', infosToaster)

        this.$store.dispatch('concourPhase/exportConcoursPhaseComparaisonConcours', { phase_id: this.phase_id_select})
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) ||'export.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Ouvre la popup des capacités de la phase suivante
    show_capacites () {
        this.showCapacites = true
    }

    // Ferme la popup des capacités
    close_capacites () {
        this.showCapacites = false
    }

    // Ouvre la popup des comparaisons
    show_comparaisons () {
        this.showComparaisons = true
    }

    // Ferme la popup des comparaisons
    close_comparaisons () {
        this.showComparaisons = false
    }

    // -------------------------------------------------------------

    // ---------------------------------------- permettre la saisie

    /**
    * switchSaisieHandler
    * Cette fonction gère l'affichage de la modal de changement de saisie.
    */
    switchSaisieHandler() {
        this.showModalSwitchSaisie = true
    }

    /**
    * cancelSwitchSaisie
    * Cette fonction annule le changement de saisie en réinitialisant les variables et en fermant la modal.
    */
    cancelSwitchSaisie() {
        this.showModalSwitchSaisie = false
        this.saisieIsOpen = !this.$store.getters['session/sessionSelect'].barres_update_locked_at
        this.switchSaisieKey = 'switchSaisie_' + Math.random()
    }

    /**
    * switchSaisieSuite
    * Cette fonction gère l'enregistrement des modifications de la saisie et affiche des messages de confirmation.
    */
    switchSaisieSuite() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('session/updateSession',{session_id:this.$store.getters['session/sessionSelect'].id, payload: {barres_update_locked: this.saisieIsOpen ? 1 : 0 } } )
            .then(() => {
                this.$bvToast.hide(idInfo)
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminée.', succesToaster)

                this.saisieIsOpen = !this.$store.getters['session/sessionSelect'].barres_update_locked_at
                this.switchSaisieKey = 'switchSaisie_' + Math.random()
                this.showModalSwitchSaisie = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // -------------------------------------------------------------

    // -----------------------------------validation des barres

    // Affiche la popup de validation / invalidation des barres
    show_validation_barres (valide: number) {
        this.tableauDesConcoursValidables = this.$store.state.definitionDesBarres.tableauDesConcours.filter((tc: any) => !tc.deliberation.barreValide && tc.ajustements_validated_at && !tc.barreNoteComplete)
        // console.log('tableauDesConcoursToValide', this.tableauDesConcoursValidables)
        this.valide = valide
        this.showValidationBarres = true
    }

    // Fermer la popup de validation / invalidation des barres
    cancel_validation_barres () {
        this.showValidationBarres = false
    }

    /**
     * confirm_validation_barres
     * Cette fonction effectue la validation des barres en cours.
     * @param aucun
    */
    confirm_validation_barres () {
        this.validationEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Validation en cours ...', infosToaster)

        this.$store.dispatch('definitionDesBarres/validationnDesBarres', { phase_id: this.phase_id_select } )
            .then(() => {
                this.showValidationBarres = false
                Vue.set(this.params, 'filter-phase_id', this.phase_id_select)
                this.$store.dispatch('definitionDesBarres/getTableauDesConcours', this.params)
                    .then((response) => {
                        const idSucces2 = 't_succes_' + Math.random()

                        this.setDataForGenericTab(response.data.data)
                        const succesToaster2 = {
                            id: idSucces2,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }

                        const message = 'Validation terminée.'
                        this.$bvToast.toast(message, succesToaster2)
                    })
                    .catch(() => {
                        this.errorKeeper = this.$store.getters['definitionDesBarres/error']
                    }).finally(() => {
                        this.$bvToast.hide(idInfo)
                        this.validationEnCours = false
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
                this.$bvToast.hide(idInfo)
                this.validationEnCours = false
            })
            
    }

    // -------------------------------------------------------------

    // -----------------------------------validation barre par barre

    /**
     * validateInvalidateBarre
     * Cette fonction permet d'afficher la popup de confirmation de validation ou d'invalidation des barres d'un concours.
     * @param params: le concours
     */
    validateInvalidateBarre(params: any) {
        this.dataToValidate = params
        this.showValidateInvalidateBarre = true
    }

    /**
     * cancelValidateInvalidateBarre
     * Cette fonction permet d'annuler la validation ou l'invalidation des barres d'un concours.
     * @param aucun
     */
    cancelValidateInvalidateBarre () {
        this.dataToValidate = null
        this.showValidateInvalidateBarre = false
    }

    /**
     * Valide ou invalide les barres
     * @param {number} state - Etat de validation
     * @returns {void}
     */
    validateInvalidateBarreSuite(): void {
        this.validationEnCours = true
        /* TOAST enregistrement en cours */
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        /* fin toast */

        const params = {
            concourphase: this.dataToValidate.id,
            _method: 'PUT',
            validate: this.dataToValidate.deliberation.barreValide ? 0 : 1,
            data: {
                bar_rules: this.dataToValidate.deliberation.bar_rules,
                barres: JSON.parse(JSON.stringify(this.dataToValidate.deliberation.barres))
            }
        }
        this.$store.dispatch('definitionDesBarres/putDefinitionDesBarres', params)
            .then(() => {
                Vue.set(this.params, 'filter-phase_id', this.phase_id_select)
                this.$store.dispatch('definitionDesBarres/getTableauDesConcours', this.params)
                    .then((response) => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }

                        const message = (!this.dataToValidate.deliberation.barreValide ? 'Validation' : 'Invalidation' ) + ' terminée.'
                        this.setDataForGenericTab(response.data.data)
                        this.cancelValidateInvalidateBarre()
                        this.$bvToast.toast(message, succesToaster)
                    })
                    .catch(() => {
                        this.errorKeeper = this.$store.getters['definitionDesBarres/error']
                    }).finally(() => {
                        this.validationEnCours = false
                        this.$bvToast.hide(idInfo)
                    })
            })
            .catch(() => {
                this.errorKeeper = this.$store.getters['definitionDesBarres/error']
                this.$bvToast.hide(idInfo)
                this.validationEnCours = false
            })
    }

    // -------------------------------------------------------------

    // ----------------------------------------------------------- ?

    // Change le flag d'enregistrement car des données sont modifiées et non enregistrées
    update_work () {
        // Compare la saisie en cours à la dernière pour voir s'il y a eu des modifs
        const compare_old = JSON.stringify(this.$store.state.definitionDesBarres.old_saisie)
        const compare_actual = JSON.stringify(this.$store.state.definitionDesBarres.saisie_en_cours)
        if (compare_actual !== compare_old) {
            this.$store.commit('definitionDesBarres/SET_WORK_PROGRESS', true)
            this.setDataForGenericTab(this.$store.getters['definitionDesBarres/tableauDesConcours'])
        }
    }

    // -------------------------------------------------------------

    // ------------------------------------------------generic table

    // TODO : Création dynamique des entêtes de colonnes
    // -> Colonnes dynamiques pour les groupes de comparaisons : 1 groupe = 1 colonne
    create_fields (poData: any = null) {
        this.genericfields = []
        this.barres_actives = []
        this.ranking_groups = []

        const tableauDesConcours = this.$store.getters['definitionDesBarres/tableauDesConcours']

        this.genericfields.push({ key: 'concour.name', label: 'Concours', sortable: true, class: '', type: 'text' })
        this.genericfields.push({ key: 'banqueId', label: 'Filière', sortable: true, class: '', type: 'text' })
        if(this.modeMonoColonneBarre){
            this.genericfields.push({ key: 'barres', label: 'Barres', sortable: false, class: 'text-center', type: 'html' })
        } else {
            // Parcours des lignes pour récupérer les barres actives
            for (const concour of tableauDesConcours) {

                if( concour.deliberation &&  concour.deliberation.barres){
                    for(const barre of concour.deliberation.barres) {
                        if(!this.hiddenBarres.includes(barre.type)) {
                            for(const threshold of barre.thresholds) {
                                if (!this.ranking_groups.find((b: any) => String(b.ranking_group_id) === String(barre.ranking_group_id))){
                                    this.ranking_groups.push({
                                        ranking_group_id: barre.ranking_group_id,
                                        name: (concour.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, concour.deliberation.bar_rules).name : barre.name)
                                    })
                                }
                                if (this.barres_actives.length !== 0) {
                                    const check_barre = this.barres_actives.find((b: any) => String(b.id) === String(barre.id) && String(b.bar_rule_id) === String(threshold.bar_rule_id))
                                    if (!check_barre) {
                                        //this.barres_actives.push(threshold)
                                        this.barres_actives.push({
                                            id:barre.id,
                                            type: barre.type,
                                            ranking_group_id: barre.ranking_group_id,
                                            bar_rule_id: threshold.bar_rule_id,
                                            name: (concour.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, concour.deliberation.bar_rules).name :  barre.name)
                                        })
                                    }
                                } else{
                                    this.barres_actives.push({
                                        id:barre.id,
                                        type: barre.type,
                                        ranking_group_id: barre.ranking_group_id,
                                        bar_rule_id: threshold.bar_rule_id,
                                        name:  (concour.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, concour.deliberation.bar_rules).name : barre.name)
                                    })
                                }
                            }
                        }
                    }
                }
            }

            for (const barre of this.barres_actives) {
                this.genericfields.push({ key: 'barre_' + barre.id, label: barre.name, sortable: false, class: 'col-min-width', type: 'text' })
            }
        }

        this.genericfields.push({ key: 'barre_valide', label: 'Validation', sortable: true, class: 'text-center', type: 'text' })

        this.genericfields.push({ key: 'barres_updated_at', label: 'Dernière modification', sortable: true, sortDirection: 'asc', class: '' })

        this.genericfields.push({ key: 'candidats_count', label: 'Candidats inscrits', sortable: true, class: 'text-center', type: 'text' })

        for(const rang of this.ranking_groups) {
            this.genericfields.push({ key: 'rang_' + rang.ranking_group_id, label: 'Candidats ' + rang.name, sortable: false, class: 'col-min-width text-center', type: 'text' })
        }

        // this.genericfields.push({ key: 'candidats_recus_count', label: 'Candidats Admis', sortable: true, class: 'text-center', type: 'text' })

        this.setDataForGenericTab(poData ? poData : tableauDesConcours)
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const concour = result && result.concour && result.concour.name ? result.concour.name : '-'
                const filiere =
                    result && result.concour && result.concour.banque && result.concour.banque.name
                        ? result.concour.banque.name
                        : '-'


                const line: any = [
                    { label: '', item: concour, type: 'text', typeAction: null, class: '' },
                    { label: '', item: filiere, type: 'text', typeAction: null, class: '' }
                ]

                const countBarreWithoutAnonymous =  this.getCountBarreWithoutAnonymous(result)

                if(this.modeMonoColonneBarre){
                    let items = '<div class="row">'

                    for(const barreDelib of result.deliberation.barres) {
                        if(!this.hiddenBarres.includes(barreDelib.type)) {
                            items += '<div class="col-' + (12 / countBarreWithoutAnonymous) + '">'
                            for(const threshold of barreDelib.thresholds) {
                                const nameTemp = barreDelib.name + ' - ' + (this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, result) ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, result.deliberation.bar_rules).name : '')
                                items += '<div class="mb-1 mt-0" style="font-size: 11px;">' + nameTemp + ': </div><input type="number" data-resultid="'+result.id+'" data-barreid="'+barreDelib.id+'" data-barruleid="' + threshold.bar_rule_id + '" value="' + (threshold.value ? threshold.value : '') + '" step="1" min="0" ref="inputbarre" class="inputbarre form-control form-control-sm" />'
                            }
                            items += '</div>'
                        }
                    }

                    items += '</div>'
                    line.push({
                        label: 'Barres',
                        item: items,
                        type: 'html',
                        typeAction: null,
                        class: 'text-center'
                    })

                } else {
                    result.barreNoteComplete = false
                    for (const barre of this.barres_actives) {
                        let check_barre: any = null
                        // const check_barre = result.deliberation.bar_rules.find((b: any) => b.id === barre.id)
                        for(const barreDelib of result.deliberation.barres) {
                            for(const threshold of barreDelib.thresholds) {
                                if(threshold.bar_rule_id === barre.bar_rule_id && barreDelib.id === barre.id) {
                                    check_barre = {
                                        barre_id: barre.id,
                                        bar_rule_id: barre.bar_rule_id,
                                        value: threshold.value
                                    }
                                }
                            }
                        }
                        if (check_barre) {
                            check_barre.concour_phase_id = result.id
                            if(check_barre.value === null) {
                                result.barreNoteComplete = true
                            }
                            line.push({
                                label: '',
                                item: check_barre.value,
                                data: check_barre,
                                type: 'inputNumber',
                                typeAction: 'input_barre',
                                minInput: 0,
                                class: 'item_action fixed_size_input_table_gen text-center col-min-width',
                                disabled: !result.ajustements_validated_at || (result.deliberation.barreValide === true || result.deliberation.barreValide === 1),
                                nullEnabled: true
                            })
                        } else {
                            line.push({ label: '', item: '', type: 'text', typeAction: null, class: '' })
                        }
                    }
                }

                line.push({
                    label:  (result.deliberation.barreValide ? 'Validé le ' + formatDateVariante(result.barres_updated_at) : 'Non validé'),
                    item: [{ name: 'circle', item: result, class: (result.deliberation.barreValide ? 'text-success' : 'text-tertiary'),  title:  (result.ajustements_validated_at ? 'ajustement validé le ' + formatDateVariante(result.ajustements_validated_at) : 'ajustement non validé') + ' - ' + (result.deliberation.barreValide ? 'barre(s) validée(s)': 'barre(s) non validée(s)')}], // (result.ajustements_validated_at ? 'Validé le ' + formatDateVariante(result.ajustements_validated_at) : 'Non validé')
                    type: 'icons',
                    typeAction: 'validationHandler',
                    class: 'text-center'
                })


                if((this.last_save === null && result.barres_updated_at) || (this.last_save && result.barres_updated_at && dateisSameOrBefore(this.last_save, result.barres_updated_at))) {
                    this.last_save = result.barres_updated_at
                }
                const updated_at = (result.barres_updated_at ? formatDateVariante(result.barres_updated_at) : ' - ')
                const updated_at_icon = [{
                    name: 'clock',
                    class: 'text-info text-small',
                    title: updated_at,
                    value_comp: updated_at,
                    typeAction: 'popupHistorique',
                    item: result
                }]



                line.push({ label: '', item: updated_at_icon, type: 'icons', typeAction: 'popupHistorique', class: 'text-center' })

                line.push({ label: '', item: result.candidats_count, type: 'text', typeAction: null, class: 'text-center' })

                for(const rang of this.ranking_groups) {
                    line.push({ label: '', item: this.getNbandidatsByRankingGroupId(result, rang.ranking_group_id), type: 'text', typeAction: null, class: 'text-center' })
                }

                // line.push({ label: '', item:result.candidats_recus_count, type: 'text', typeAction: null, class: 'text-center' })

                this.dataForTab.push(line)
            }
        }
    }

    getNbandidatsByRankingGroupId (result: any, ranking_group_id: any) {
        if(result.candidatsByRankingGroup) {
            for(let i = 0; i < result.candidatsByRankingGroup.length;  i++) {
                if(String(ranking_group_id) === String(result.candidatsByRankingGroup[i].ranking_group_id)) {
                    return result.candidatsByRankingGroup[i].ranking_group_id_count
                }
            }
        }
        return '-'
    }

    // Initialisation des filtres
    setFiltersForGenericTab() {
        this.$store.dispatch('banque/getBanques', { perPage: 0 }).then(() =>{
            const deroulant_banques = this.$store.getters['banque/banques'].map((banque: any) => {
                return { index: banque.id, name: banque.name }
            })

            for (let i = 0; i < this.$store.getters['banque/banques'].length; i++) {
                for ( let j = 0; j < this.$store.getters['banque/banques'][i].phases.length; j++) {
                    if( i === 0 &&  j === 0) {
                        this.phase_id_select = this.$store.getters['banque/banques'][i].phases[j].phase_id
                    }
                    const optTemp:any = {id:this.$store.getters['banque/banques'][i].phases[j].phase_id, name: this.$store.getters['banque/banques'][i].phases[j].name, id_phase_suivante: this.$store.getters['banque/banques'][i].phases[j].id_phase_suivante }
                    if(this.phases_options.length === 0 || !this.phases_options.find((p: any) => p.id === optTemp.id)){
                        this.phases_options.push(optTemp)
                    }
                }
            }

            this.$store.dispatch('concour/getConcours', { perPage: 0 }).then(() => {
                const concours = this.$store.getters['concour/concours']
                const deroulant_concours = concours.map((concour: any) => {
                    return { index: concour.id, name: concour.name }
                })

                this.filtres = [
                    {
                        libelle: 'Concours',
                        defautOptionlibelle: 'Rechercher un',
                        model: 'concour.id',
                        value: '',
                        index: 'concour.id',
                        datas: deroulant_concours,
                        loading: this.$store.getters['definitionDesBarres/loading'],
                        options: { type: 'deroulant', fieldsKey: 'concour.name', strict: true }
                    },
                    {
                        libelle: 'Filière',
                        defautOptionlibelle: 'Rechercher une',
                        model: 'banqueId',
                        value: '',
                        index: 'banqueId',
                        datas: deroulant_banques,
                        loading: this.$store.getters['definitionDesBarres/loading'],
                        options: { type: 'deroulant', fieldsKey: 'banqueId', strict: true }
                    }
                ]
                this.load()
            })
        })
    }

    // Evenements du tableau
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                case 'input_barre':
                    paParams[2].value = paParams[1]
                    this.inputBarreChangeHandler(paParams[2])

                    break
                case 'popupHistorique':
                    this.setTogglePopupHistoriqueConcourPhase(paParams[1][0].item.id)
                    break;

                case 'validationHandler':
                    this.validateInvalidateBarre(paParams[1][0].item)
                    break;
                default:
                    break
            }
        }
    }

    // Chargement des résultats
    loadHandler(params: any) {
        Vue.set(params, 'filter-phase_id', this.phase_id_select)
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store
                .dispatch('definitionDesBarres/getMoreTableauDesConcours', params)
                .then(() => {
                    const tableauDesConcours: any = this.$store.getters['definitionDesBarres/tableauDesConcours']
                    if(tableauDesConcours) {
                        this.setDataForGenericTab(tableauDesConcours)
                    }
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        Vue.set(params, 'filter-phase_id', this.phase_id_select)
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store
                .dispatch('definitionDesBarres/getTableauDesConcours', params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.getters['definitionDesBarres/tableauDesConcours'])
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    // -------------------------------------------------------------

    // ----------------------------------------- modification barres

    barreChangeHandler (value: any, concourId: any, barreId: any, barruleId: any) {
        const concourTemp = this.$store.getters['definitionDesBarres/tableauDesConcours'].find((conc: any) => String(conc.id) === String(concourId))
        for(const barre of concourTemp.deliberation.barres) {
            if(String(barre.id) === String(barreId)) {
                for(const threshold of barre.thresholds) {
                    if(String(threshold.bar_rule_id) === String(barruleId)) {
                        threshold.value = value
                    }
                }
            }
        }
        const retour = {
            bar_rules: concourTemp.deliberation.bar_rules,
            barres: concourTemp.deliberation.barres
        }
        console.log(retour)

    }

    inputBarreChangeHandler(params: any) {
        // console.log('-----------inputBarreChangeHandler')
        // debounce(() => {
        // console.log('-----------inputBarreChangeHandler debounce')
        this.inputBarreChangeHandlerSuite(params)
        //}, 1500)
    }

    inputBarreChangeHandlerSuite(params: any) {
        //  console.log('-----------inputBarreChangeHandler suite')
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        const data: any = {
            concourPhase_id: params.concour_phase_id,
            data: {
                bar_id: params.barre_id,
                bar_rule_id: params.bar_rule_id,
                value: (params.value ? Number(params.value) : null)
            }
        }
        this.$store
            .dispatch('definitionDesBarres/updateConcourPhaseBarThresholdValue' , data)
            .then(() => {
                const tableauDesConcours: any = this.$store.getters['definitionDesBarres/tableauDesConcours']
                if(tableauDesConcours) {
                    this.setDataForGenericTab(tableauDesConcours)
                }
                this.$bvToast.hide(idInfo)
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminée.', succesToaster)

            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    // TODO : Enregistre la saisie
    // -> Enregistrer la saisie des barres de tout les concours
    save () {
        this.$store.commit('definitionDesBarres/SET_WORK_PROGRESS', false)
        this.setDataForGenericTab(this.$store.getters['definitionDesBarres/tableauDesConcours'])
    }

    // -------------------------------------------------------------

    // ---------------------------------- chargement / construction


    @Watch('user_session_id')
    @Watch('phase_id_select')
    refreshInterface() {
        if(!this.firstLoading) {
            this.last_save = null
            this.load()
        }
    }

    getCountBarreWithoutAnonymous (concour: any) {
        let count = 0;
        if(concour.deliberation && concour.deliberation.barres) {
            for(let i = 0; i < concour.deliberation.barres.length; i++) {
                if(!this.hiddenBarres.includes(concour.deliberation.barres[i].type)) {
                    count++
                }
            }
        }
        return count
    }

    getBarreRuleByIdOfConcour(id: any, bar_rules: any) {
        for(const bar_rule of bar_rules){
            if(bar_rule.id === id) {
                return bar_rule
            }
        }
        return null
    }

    load() {
        const params = {}
        Vue.set(params, 'filter-phase_id', this.phase_id_select)
        this.params = params

        for(let i = 0; i < this.phases_options.length; i++) {
            if(this.phases_options[i].id === this.phase_id_select){
                this.id_phase_suivante = this.phases_options[i].id_phase_suivante
            }
        }

        this.$store
            .dispatch('definitionDesBarres/getTableauDesConcours', params)
            .then(() => {
                const tableauDesConcours: any = this.$store.getters['definitionDesBarres/tableauDesConcours']
                if(tableauDesConcours) {
                    this.create_fields()

                    this.setDataForGenericTab(tableauDesConcours)
                    this.firstLoading = false

                }
                // this.firstLoading = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })

    }

    mounted() {

        this.firstLoading = true
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.setFiltersForGenericTab()
        }
        this.saisieIsOpen = !this.$store.getters['session/sessionSelect'].barres_update_locked_at
        this.switchSaisieKey = 'switchSaisie_' + Math.random()
        if(process.env.VUE_APP_REVERB_HOST && process.env.VUE_APP_REVERB_HOST !== '') {
            this.switchListener()
        }
    }

    // -------------------------------------------------------------
}
